import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { AxiosError } from 'axios'
import { IContact, IContactCategory, IContactType } from '../../features/Contacts/types'
import { IDocumentCategory } from '../../features/Documents/types'
import { OMNIALOG_CORE_API_URL } from '../axios/const'
import { fetchData } from './fetchData'

const apiAxiosBaseQuery =
  ({
    baseUrl,
  }: {
    baseUrl: string
  }): BaseQueryFn<
    {
      url: string
      method: 'GET' | 'POST' | 'PUT' | 'DELETE'
      domain: 'Core'
      body?: any
    },
    unknown,
    unknown
  > =>
  async ({ url, method, domain, body }) => {
    try {
      return await fetchData(domain, method, url, body)
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const apiCoreSlice = createApi({
  reducerPath: 'apiCore',
  baseQuery: apiAxiosBaseQuery({
    baseUrl: OMNIALOG_CORE_API_URL,
  }),
  tagTypes: ['DocumentCategory', 'ContactType', 'ContactCategory'],
  endpoints(build) {
    return {
      // Core //
      // Documents //
      getDocumentCategory: build.query<IDocumentCategory, number>({
        query: (id) => ({
          url: `document-category/${id}`,
          method: 'GET',
          domain: 'Core',
        }),
      }),
      getSASForUploadFile: build.query<any, void>({
        query: () => ({
          url: '/document/sas/upload',
          method: 'GET',
          domain: 'Core',
        }),
      }),

      getSASForReadFile: build.query<string, void>({
        query: () => ({
          url: '/document/sas/read',
          method: 'GET',
          domain: 'Core',
        }),
      }),
      getSASForDeleteFile: build.query<string, void>({
        query: () => ({
          url: '/document/sas/delete',
          method: 'GET',
          domain: 'Core',
        }),
      }),
      getDocumentsByEntityId: build.query<any, number>({
        query: (entityId) => ({
          url: `/document/entity/${entityId}`,
          method: 'GET',
          domain: 'Core',
        }),
      }),
      // Documents //
      // Contacts //
      getContactCategory: build.query<IContactCategory[], { tenantId: number; customerId: number }>(
        {
          query: ({ tenantId, customerId }) => {
            const customer = customerId ? `customer/${customerId}/` : ''
            return {
              url: `tenant/${tenantId}/${customer}contact-category`,
              method: 'GET',
              domain: 'Core',
            }
          },
        }
      ),
      getContactType: build.query<
        IContactType[],
        { contactCategoryId: number; tenantId: number; customerId?: number }
      >({
        query: ({ tenantId, customerId, contactCategoryId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `tenant/${tenantId}/${customer}contactCategory/${contactCategoryId}/contact-type`,
            method: 'GET',
            domain: 'Core',
          }
        },
        providesTags: () => [{ type: 'ContactType', id: 'ContactType' }],
      }),
      getContactAuditByEntityId: build.query<IContact[], number>({
        query: (entityId) => ({
          url: `/contact-audit/${entityId}`,
          method: 'GET',
          domain: 'Core',
        }),
        providesTags: (result, error) => [{ type: 'ContactType', id: 'ContactType' }],
      }),
      getContactByEntityId: build.query<IContact[], number>({
        query: (entityId) => ({
          url: `/contact/${entityId}`,
          method: 'GET',
          domain: 'Core',
        }),
        providesTags: (result, error) => [{ type: 'ContactType', id: 'ContactType' }],
      }),
      getContactTypesByTenantId: build.query<IContactType[], number>({
        query: (tenantId) => ({
          url: `/tenant/${tenantId}/contact-type`,
          method: 'GET',
          domain: 'Core',
        }),
        providesTags: (result, error) => [{ type: 'ContactType', id: 'ContactType' }],
      }),
      getContactTypesByCustomerId: build.query<IContactType[], number>({
        query: (customerId) => ({
          url: `/customer/${customerId}/contact-type`,
          method: 'GET',
          domain: 'Core',
        }),
        providesTags: (result, error) => [{ type: 'ContactType', id: 'ContactType' }],
      }),
      addContactType: build.mutation<IContactType, IContactType>({
        query: (contactType) => ({
          url: '/contact-type',
          method: 'POST',
          domain: 'Core',
          body: contactType,
        }),
        invalidatesTags: [
          { type: 'ContactType', id: 'ContactType' },
          { type: 'ContactCategory', id: 'ContactCategory' },
        ],
      }),
      updateContactType: build.mutation<IContactType, IContactType>({
        query: (contactType) => ({
          url: '/contact-type',
          method: 'PUT',
          domain: 'Core',
          body: contactType,
        }),
        invalidatesTags: [
          { type: 'ContactType', id: 'ContactType' },
          { type: 'ContactCategory', id: 'ContactCategory' },
        ],
      }),
      deleteContactType: build.mutation<IContactType, number>({
        query: (id) => ({
          url: `/contact-type/${id}`,
          method: 'DELETE',
          domain: 'Core',
        }),
        invalidatesTags: [
          { type: 'ContactType', id: 'ContactType' },
          { type: 'ContactCategory', id: 'ContactCategory' },
        ],
      }),
      addContactCategory: build.mutation<IContactCategory, IContactCategory>({
        query: (contactCategory) => ({
          url: '/contact-category',
          method: 'POST',
          domain: 'Core',
          body: contactCategory,
        }),
        invalidatesTags: [{ type: 'ContactCategory', id: 'ContactCategory' }],
      }),
      updateContactCategory: build.mutation<IContactCategory, IContactCategory>({
        query: (contactCategory) => ({
          url: '/contact-category',
          method: 'PUT',
          domain: 'Core',
          body: contactCategory,
        }),
        invalidatesTags: [{ type: 'ContactCategory', id: 'ContactCategory' }],
      }),
      deleteContactCategory: build.mutation<IContactCategory, number>({
        query: (id) => ({
          url: `/contact-category/${id}`,
          method: 'DELETE',
          domain: 'Core',
        }),
        invalidatesTags: [{ type: 'ContactCategory', id: 'ContactCategory' }],
      }),
      getContactCategoriesByTenantId: build.query<IContactCategory[], number>({
        query: (tenantId) => ({
          url: `/tenant/${tenantId}/contact-category`,
          method: 'GET',
          domain: 'Core',
        }),
        providesTags: (result, error) => [{ type: 'ContactCategory', id: 'ContactCategory' }],
      }),
      getContactCategoriesByCustomerId: build.query<IContactCategory[], number>({
        query: (customerId) => ({
          url: `/customer/${customerId}/contact-category`,
          method: 'GET',
          domain: 'Core',
        }),
        providesTags: (result, error) => [{ type: 'ContactCategory', id: 'ContactCategory' }],
      }),
      // Contacts //
    }
  },
})

export const {
  useGetDocumentCategoryQuery,
  useGetSASForReadFileQuery,
  useGetSASForUploadFileQuery,
  useGetSASForDeleteFileQuery,
  useGetDocumentsByEntityIdQuery,
  useGetContactCategoryQuery,
  useGetContactTypeQuery,
  useGetContactByEntityIdQuery,
  useGetContactTypesByTenantIdQuery,
  useGetContactTypesByCustomerIdQuery,
  useAddContactTypeMutation,
  useUpdateContactTypeMutation,
  useDeleteContactTypeMutation,
  useAddContactCategoryMutation,
  useUpdateContactCategoryMutation,
  useDeleteContactCategoryMutation,
  useGetContactCategoriesByTenantIdQuery,
  useGetContactCategoriesByCustomerIdQuery,
  useGetContactAuditByEntityIdQuery,
  endpoints: apiEndpoints,
} = apiCoreSlice
