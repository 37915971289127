import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { removeKeys } from '../../utils';
import AssetHire from './AssetHire';
import messages from './messages';

const AssetHireList: React.FC = () => {
    const { control, getValues, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'bookings',
    });

    const { formatMessage } = useIntl();
    const orderId = watch('id');
    console.log('🚀 ~ orderId:', orderId)
    const copyAssetHire = (index: number) => {
        const currentBookings = getValues('bookings');

        const assetHire = removeKeys(currentBookings[index], [
            'id',
            'bookingId',
            'waypointId',
            'assetHireBookingId'
        ]);

        if (!assetHire) return;

        append(assetHire);
    };


    return (
        <>
            {fields.map((field, index) => (
                <AssetHire
                    key={field.id}
                    index={index}
                    removeAssetHire={() => remove(index)}
                    copyAssetHire={() => copyAssetHire(index)}
                />
            ))}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={async () => {
                    append({
                        consignmentReferenceNumber: '',
                        trackingNumber: '',
                        waypoints: [{ waypointTypeId: 1 }, { waypointTypeId: 3 }],
                        workforce: [],
                        ...(orderId ? { orderId } : {})
                        // waypointType: 1 is pickup, 3 is return
                    });
                }
                }
                sx={{ marginTop: 2 }}
            >
                {formatMessage(messages.addAssetHire)}
            </Button >

        </>
    );
};

export default AssetHireList;
