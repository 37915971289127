import { Option } from '../../../components/Forms/FormSelect'
import { WaypointType } from '../../../types/common'
import { IShipmentFormInput } from './ShipmentForm'
import { LoadDto, ShipmentDto, TransitDetailDto, WaypointDto } from './types'

export const getCustomerName = (customers: any[], customerId?: number) => {
  return customers.find((customer) => customer.id === customerId)?.name
}

export const getCarrierOption = (carrierId?: number, transitIdentifier?: string) => {
  if (!carrierId) {
    return 'noPreference'
  }
  if (transitIdentifier) {
    return 'preBookedTransit'
  }
  return 'preferredCarrier'
}

export const getAvailableLocations = (
  allLocations: Option[],
  usedLocationAuditIds: number[]
): Option[] => {
  return allLocations.filter((location) => !usedLocationAuditIds.includes(+location.value))
}

export const mapToShipmentFormInput = (shipmentData: ShipmentDto): IShipmentFormInput => {
  if (
    shipmentData.tenantId === undefined ||
    shipmentData.customerId === undefined ||
    !shipmentData.bookings
  ) {
    throw new Error('Required shipment data is missing.')
  }

  const mapTransitDetail = (transitDetail: TransitDetailDto) => {
    return {
      id: transitDetail.id ?? undefined,
      carrierId: transitDetail.carrierId,
      modeOfTransit: transitDetail.transportModeId === 0 ? -1 : transitDetail.transportModeId ?? -1,
      transitIdentifier: transitDetail.transitIdentifier ?? undefined,
      crossBorder: transitDetail.isCrossBorder ?? undefined,
      carrierOption: getCarrierOption(transitDetail.carrierId, transitDetail.transitIdentifier),
    }
  }

  const getLocationIdFromWaypoint = (waypoint: WaypointDto) => {
    return waypoint.locationAudit?.id ?? -1
  }

  const mapStop = (stop: WaypointDto | undefined) => {
    if (stop) {
      return {
        id: stop.id ?? undefined,
        locationId: stop.locationAudit?.id ?? -1,
        scheduledDate: new Date(stop.scheduledDate ?? new Date()),
        contactId: stop.contactId ?? undefined,
        specialInstructions: stop.instructions ?? undefined,
        transitDetails: mapTransitDetail(
          stop.transitDetail ?? {
            transportModeId: -1,
          }
        ),
      }
    }
    return undefined
  }

  return {
    id: shipmentData.id ?? undefined,
    submittedDate: shipmentData.submittedDate ? new Date(shipmentData.submittedDate) : undefined,
    tenantId: shipmentData.tenantId ?? undefined,
    revision: shipmentData.revision ?? undefined,
    customerId: shipmentData.customerId,
    customerReferenceNumber: shipmentData.customerReferenceNumber ?? '',
    shipmentNumber: shipmentData.code ?? undefined,
    description: shipmentData.description ?? '',
    statuses: shipmentData.statuses ?? [],
    bookings: shipmentData.bookings.map((consignment) => {
      if (!consignment.waypoints) {
        throw new Error('Consignment stops are missing.')
      }

      const shipFromStop = consignment.waypoints.find((stop) => stop.waypointTypeId === 1)
      const deliverToStop =
        consignment.waypoints.find((stop) => stop.waypointTypeId === 3) || shipFromStop

      if (!shipFromStop || !deliverToStop) {
        throw new Error('ShipFrom or DeliverTo waypoint is missing.')
      }

      const waypointStops = consignment.waypoints.filter((stop) => stop.waypointTypeId === 2)

      const emptyReturnCollection = consignment.waypoints.find(
        (waypoint) => waypoint.waypointTypeId === WaypointType.emptyContainerCollection
      )

      const emptyContainerReturn = consignment.waypoints.find(
        (waypoint) => waypoint.waypointTypeId === WaypointType.emptyContainerReturn
      )
      const returnToStop = consignment.waypoints.find(
        (waypoint) => waypoint.waypointTypeId === WaypointType.return
      )

      const shipFrom = {
        id: shipFromStop.id ?? undefined,
        locationId: getLocationIdFromWaypoint(shipFromStop),
        consignmentId: consignment.id ?? undefined,
        locationAudit: shipFromStop.locationAudit ?? undefined,
        returnTo: returnToStop ? true : false,
        contactId: shipFromStop.contactId ?? undefined,
        clearingRequired: shipFromStop.clearingRequired ?? undefined,
        specialInstructions: shipFromStop.instructions ?? undefined,
        loadingDate: shipFromStop.scheduledDate ? new Date(shipFromStop.scheduledDate) : new Date(),
        emptyContainerCollection: emptyReturnCollection ? true : false,
        transitDetails: mapTransitDetail(
          shipFromStop.transitDetail ?? {
            transportModeId: -1,
          }
        ),
        defaultValue: undefined,
      }

      const deliverTo = {
        id: deliverToStop.id ?? undefined,
        consignmentId: consignment.id ?? undefined,
        locationId: getLocationIdFromWaypoint(deliverToStop),
        clearingRequired: deliverToStop.clearingRequired ?? undefined,
        contactId: deliverToStop.contactId ?? undefined,
        deliveryDate: deliverToStop.scheduledDate
          ? new Date(deliverToStop.scheduledDate)
          : new Date(),
        specialInstructions: deliverToStop.instructions ?? undefined,
        emptyContainerReturn: emptyContainerReturn ? true : false,
        transitDetails: emptyContainerReturn
          ? mapTransitDetail(
              deliverToStop.transitDetail ?? {
                transportModeId: -1,
              }
            )
          : undefined,
      }

      const waypoints = waypointStops.map((waypoint) => ({
        id: waypoint.id ?? undefined,
        locationId: getLocationIdFromWaypoint(waypoint),
        clearingRequired: waypoint.clearingRequired ?? undefined,
        loadingDate: waypoint.scheduledDate ? new Date(waypoint.scheduledDate) : new Date(),
        specialInstructions: waypoint.instructions ?? undefined,
        consignmentId: consignment.id ?? undefined,
        contactId: waypoint.contactId ?? undefined,
        transitDetails: mapTransitDetail(
          waypoint.transitDetail ?? {
            transportModeId: -1,
          }
        ),
      }))

      const packaging = consignment.loads?.map((load: LoadDto) => {
        if (!load.detail || !load.detail.physicalProperties) {
          throw new Error('Load detail or physical properties are missing.')
        }

        const props = load.detail.physicalProperties
        const pkg: any = {
          id: load.id ?? undefined,
          isHazardous: load.isHazardous ?? false,
          shipmentBookingId: load.shipmentBookingId ?? undefined,
          packagingTemplateId: load.detail.loadTemplateId ?? undefined,
          physicalPropertiesId: props.id ?? undefined,
          packagingTypeId: load.detail.loadTypeId ?? undefined,
          сonsignmentId: consignment.id ?? undefined,
          packagingDetailId: load.detail.id ?? undefined,
          numberOfPackages: load.quantity ?? undefined,
          useDeclaredValue: undefined,
          declaredContentDescription: load.description,
          weight: props.grossWeight ?? undefined,
          length: props.length ?? undefined,
          width: props.width ?? undefined,
          height: props.height ?? undefined,
          isStackable: load.detail.isStackable ?? undefined,
          weightUnit: props.weightMeasureUnitId ?? undefined,
          dimensionUnit: props.lengthMeasureUnitId ?? undefined,
          declaredValue: load.value ?? undefined,
          products: load.contents?.map((content) => ({
            id: content.id ?? undefined,
            productDescription: content.description ?? undefined,
            productId: content.productId ?? undefined,
            countryOfOriginId: content.countryOfOriginId ?? undefined,
            quantity: content.quantity ?? 0,
            unitPrice: content.unitPrice ?? 0,
            useProduct: content.productId !== undefined ? true : undefined,
            defaultValue: undefined,
          })),
        }

        if (load.value !== undefined) {
          pkg.useDeclaredValue = true
          pkg.amountInsured = load.value
          pkg.insuranceRequired = true
        }

        // If temperature setting
        if (load.temperatureSetting) {
          pkg.temperatureControlled = true
          pkg.temperatureSettingId = load.temperatureSetting.id ?? undefined
          pkg.temperatureRange = load.temperatureSetting.temperatureRangeId ?? undefined
          pkg.setPointUnitId = load.temperatureSetting.temperatureUnitId ?? undefined
          pkg.setPoint = load.temperatureSetting.setPoint ?? undefined
          pkg.lowerWarning = load.temperatureSetting.lowerThresholdWarning ?? undefined
          pkg.upperWarning = load.temperatureSetting.upperThresholdWarning ?? undefined
          pkg.lowerCritical = load.temperatureSetting.lowerThresholdCritical ?? undefined
          pkg.upperCritical = load.temperatureSetting.upperThresholdCritical ?? undefined
        }

        return pkg
      })

      return {
        id: consignment.id ?? undefined,
        bookingId: consignment.bookingId ?? undefined,
        referenceNumber: consignment.referenceNumber ?? '',
        trackingNumber: consignment.trackingNumber ?? undefined,
        currencyId: consignment.currencyId!,
        emptyContainerCollection: mapStop(emptyReturnCollection),
        emptyContainerReturn: mapStop(emptyContainerReturn),
        description: consignment.description ?? '',
        shipperId: consignment.shipperId ?? -1,
        returnTo: mapStop(returnToStop),
        shipFrom,
        deliverTo,
        packaging,
        waypoints,
      }
    }),
  }
}

export const mapToShipmentData = (data: IShipmentFormInput): ShipmentDto => {
  const shipmentData: ShipmentDto = {
    id: data.id ?? undefined,

    tenantId: data.tenantId ?? undefined,
    customerId: data.customerId,
    revision: data.revision ?? undefined,
    customerReferenceNumber: data.customerReferenceNumber ?? undefined,
    code: data.shipmentNumber ?? undefined,
    description: data.description ?? undefined,
    submittedDate: undefined,
    acceptedDate: undefined,
    underReviewUntil: undefined,
    underReviewByUserId: undefined,
    customer: undefined,
    shipper: undefined,
    bookings:
      data.bookings?.map((consignment) => {
        const baseSequence = 1

        const waypoints: WaypointDto[] = [
          {
            id: consignment.shipFrom.id,
            consignmentId: consignment.id,
            bookingId: consignment.bookingId,
            waypointTypeId: WaypointType.shipFrom,
            contactId: consignment.shipFrom.contactId ?? undefined,
            locationAuditId: consignment.shipFrom.locationId ?? -1,
            scheduledDate: consignment.shipFrom.loadingDate?.toISOString(),
            sequence: baseSequence,
            clearingRequired: consignment.shipFrom.clearingRequired ?? false,
            instructions: consignment.shipFrom.specialInstructions ?? undefined,
            transitDetail: {
              id: consignment.shipFrom.transitDetails.id ?? 0,
              waypointId: consignment.shipFrom.id ?? 0,
              transportModeId:
                consignment.shipFrom.transitDetails.modeOfTransit === -1
                  ? 0
                  : consignment.shipFrom.transitDetails.modeOfTransit ?? 0,
              isCrossBorder: consignment.shipFrom.transitDetails.crossBorder ?? undefined,
              carrierId: consignment.shipFrom.transitDetails?.carrierId ?? undefined,
              transitIdentifier: consignment.shipFrom.transitDetails.transitIdentifier ?? undefined,
            },
          },
          ...(consignment.waypoints?.map((waypoint, idx) => ({
            id: waypoint.id,
            consignmentId: consignment.id ?? 0,
            bookingId: consignment.bookingId,
            waypointTypeId: WaypointType.waypoint,
            contactId: waypoint.contactId ?? undefined,
            locationAuditId: waypoint.locationId ?? -1,
            scheduledDate: waypoint.loadingDate?.toISOString(),
            sequence: idx + baseSequence + 1,
            clearingRequired: waypoint.clearingRequired ?? false,
            instructions: waypoint.specialInstructions ?? undefined,
            transitDetail: {
              id: waypoint.transitDetails.id ?? 0,
              waypointId: waypoint.id ?? 0,
              transportModeId:
                waypoint.transitDetails.modeOfTransit === -1
                  ? 0
                  : waypoint.transitDetails.modeOfTransit ?? 0,
              isCrossBorder: waypoint.transitDetails.crossBorder ?? undefined,
              carrierId: waypoint.transitDetails.carrierId,
              transitIdentifier: waypoint.transitDetails.transitIdentifier ?? undefined,
            },
          })) ?? []),
          {
            id: consignment.deliverTo.id,
            consignmentId: consignment.id,
            bookingId: consignment.bookingId,
            waypointTypeId: WaypointType.deliverTo,
            contactId: consignment.deliverTo.contactId ?? undefined,
            locationAuditId: consignment.deliverTo.locationId ?? -1,
            scheduledDate: consignment.deliverTo.deliveryDate?.toISOString(),
            sequence: consignment.waypoints?.length
              ? consignment.waypoints.length + 1 + baseSequence
              : 2,
            clearingRequired: consignment.deliverTo.clearingRequired ?? false,
            instructions: consignment.deliverTo.specialInstructions ?? undefined,
            transitDetail: consignment.deliverTo.transitDetails
              ? {
                  id: consignment.deliverTo.transitDetails.id ?? 0,
                  waypointId: consignment.deliverTo.id ?? 0,
                  transportModeId:
                    consignment.deliverTo.transitDetails.modeOfTransit === -1
                      ? 0
                      : consignment.deliverTo.transitDetails.modeOfTransit ?? 0,
                  isCrossBorder: consignment.deliverTo.transitDetails.crossBorder ?? undefined,
                  carrierId: consignment.deliverTo.transitDetails.carrierId ?? undefined,
                  transitIdentifier:
                    consignment.deliverTo.transitDetails.transitIdentifier ?? undefined,
                }
              : undefined,
          },
        ]

        if (
          consignment.shipFrom.emptyContainerCollection &&
          consignment.emptyContainerCollection?.locationId
        ) {
          waypoints.push({
            id: consignment.emptyContainerCollection.id ?? 0,
            consignmentId: consignment.id ?? 0,
            bookingId: consignment.bookingId,
            waypointTypeId: WaypointType.emptyContainerCollection,
            contactId: consignment.emptyContainerCollection.contactId ?? undefined,
            locationAuditId: consignment.emptyContainerCollection.locationId ?? -1,
            scheduledDate: consignment.emptyContainerCollection.scheduledDate.toISOString(),
            sequence: 0,
            clearingRequired: false,
            instructions: consignment.emptyContainerCollection.specialInstructions ?? undefined,
            transitDetail: {
              id: consignment.emptyContainerCollection.transitDetails?.id ?? 0,
              waypointId: consignment.emptyContainerCollection.id ?? 0,
              transportModeId:
                consignment.emptyContainerCollection.transitDetails.modeOfTransit === -1
                  ? 0
                  : consignment.emptyContainerCollection.transitDetails?.modeOfTransit ?? 0,
              isCrossBorder:
                consignment.emptyContainerCollection.transitDetails?.crossBorder ?? undefined,
              carrierId:
                consignment.emptyContainerCollection.transitDetails?.carrierId ?? undefined,
              transitIdentifier:
                consignment.emptyContainerCollection.transitDetails?.transitIdentifier ?? undefined,
            },
          })
        }

        if (consignment.shipFrom.returnTo && consignment.returnTo?.locationId) {
          waypoints.push({
            id: consignment.returnTo.id ?? 0,
            bookingId: consignment.bookingId,
            consignmentId: consignment.id ?? 0,
            waypointTypeId: WaypointType.return,
            contactId: consignment.returnTo.contactId ?? undefined,
            locationAuditId: consignment.returnTo.locationId ?? -1,
            sequence: -1,
            clearingRequired: false,
            instructions: consignment.returnTo.specialInstructions ?? undefined,
          })
        }

        if (
          consignment.deliverTo.emptyContainerReturn &&
          consignment.emptyContainerReturn?.locationId
        ) {
          const currentMaxSequence = Math.max(...waypoints.map((s) => s.sequence ?? 0))

          waypoints.push({
            id: consignment.emptyContainerReturn.id ?? 0,
            consignmentId: consignment.id ?? 0,
            bookingId: consignment.bookingId,
            waypointTypeId: WaypointType.emptyContainerReturn,
            contactId: consignment.emptyContainerReturn.contactId ?? undefined,
            locationAuditId: consignment.emptyContainerReturn.locationId ?? -1,
            scheduledDate: consignment.emptyContainerReturn.scheduledDate.toISOString(),
            sequence: currentMaxSequence + 1,
            clearingRequired: false,
            instructions: consignment.emptyContainerReturn.specialInstructions ?? undefined,
          })
        }

        const loads = consignment.packaging?.map((packaging) => {
          const detail = {
            id: packaging.packagingDetailId ?? 0,
            loadTypeId: packaging.packagingTypeId ?? 0,
            loadTemplateId: packaging.packagingTemplateId ?? undefined,
            loadTemplate: undefined,
            physicalPropertiesId: packaging.physicalPropertiesId ?? 0,
            physicalProperties: {
              id: packaging.physicalPropertiesId ?? 0,
              weightMeasureUnitId: packaging.weightUnit ?? undefined,
              volumeMeasureUnitId: undefined,
              densityMeasureUnitId: undefined,
              lengthMeasureUnitId: packaging.dimensionUnit ?? undefined,
              netWeight: undefined,
              grossWeight: packaging.weight ?? undefined,
              chargeableWeight: undefined,
              volume: undefined,
              density: undefined,
              height: packaging.height ?? undefined,
              length: packaging.length ?? undefined,
              width: packaging.width ?? undefined,
              radius: undefined,
            },
            quantity: packaging.numberOfPackages ?? 1,
            containerNumber: undefined,
            genSetRequired: undefined,
            isStackable: packaging.isStackable ?? undefined,
          }

          let temperatureSetting = undefined
          if (packaging.temperatureControlled) {
            temperatureSetting = {
              id: packaging.temperatureSettingId ?? 0,
              temperatureRangeId: packaging.temperatureRange ?? 0,
              temperatureRange: packaging.temperatureRange
                ? { id: packaging.temperatureRange }
                : undefined,
              temperatureUnitId: packaging.setPointUnitId ?? 0,
              temperatureUnit: packaging.setPointUnitId
                ? {
                    id: packaging.setPointUnitId,
                    measureTypeId: 0,
                    siConversionFactor: 1,
                    isSiUnit: false,
                  }
                : undefined,
              setPoint: packaging.setPoint ?? 0,
              upperThresholdWarning: packaging.upperWarning ?? undefined,
              upperThresholdCritical: packaging.upperCritical ?? undefined,
              lowerThresholdWarning: packaging.lowerWarning ?? undefined,
              lowerThresholdCritical: packaging.lowerCritical ?? undefined,
            }
          }

          const loadContents = !packaging.useDeclaredValue
            ? packaging.products?.map((product) => ({
                id: product.id ?? 0,
                loadId: packaging.id ?? 0,
                productId: product.useProduct ? product.productId ?? undefined : undefined,
                countryOfOriginId: product.countryOfOriginId ?? undefined,
                unitPrice: product.unitPrice,
                quantity: product.quantity,
                description: product.productDescription ?? product.productName,
              }))
            : undefined

          return {
            id: packaging.id ?? 0,
            consignmentId: consignment.id ?? 0,
            loadDetailId: packaging.packagingDetailId ?? 0,
            shipmentBookingId: consignment.id ?? 0,
            detail: detail,
            temperatureSettingId: temperatureSetting ? temperatureSetting.id ?? 0 : undefined,
            temperatureSetting: temperatureSetting,
            value: packaging.useDeclaredValue
              ? packaging.declaredValue
                ? packaging.declaredValue
                : undefined
              : undefined,
            quantity: packaging.numberOfPackages ?? 1,
            isHazardous: packaging.isHazardous ?? false,
            trackingNumbers: undefined,
            contents: loadContents,
            description: packaging.declaredContentDescription ?? undefined,
          }
        })

        return {
          id: consignment.id ?? 0,
          bookingId: consignment.bookingId ?? 0,
          orderId: data.id ?? 0,
          shipperId: consignment.shipperId ?? undefined,
          referenceNumber: consignment.referenceNumber ?? '',
          trackingNumber: consignment.trackingNumber ?? undefined,
          currencyId: consignment.currencyId!,
          description: consignment.description ?? undefined,
          loads: loads ?? undefined,
          waypoints: waypoints,
          assetRequestAllocations: undefined,
          routeConsignmentHistories: undefined,
          shipment: undefined,
        }
      }) ?? undefined,
    assetRequests: undefined,
  }

  return shipmentData
}

export const getLocationAddress = (locations: any[], locationId: number) => {
  return locations.find((location) => location.id === locationId)?.addressAudit?.address1 ?? 'N/A'
}

export function mapContactsToOptions(contacts: any[] = []) {
  return contacts.map((contact) => ({
    label: `${contact?.contactType?.name} - ${contact?.name}`,
    value: contact.id,
  }))
}
export function mapCurrenciesToOptions(currencies: any[] = []) {
  return currencies.map((currency) => ({
    label: `${currency.ISO3} - ${currency.localisedSymbol}`,
    value: currency.id,
  }))
}
