import { AccordionDetails, Button, Grid, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetContactAuditByEntityIdQuery } from '../../../app/redux-fetch/apiCoreQuery'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import FormDatePickerInput from '../../../components/Forms/FormDatePickerInput'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import commonMessages from '../../../components/Messages/commonMessages'
import { useLocationModal } from '../hooks/useLocationModal'
import { getLocationAddress, mapContactsToOptions } from '../NewShipment/utils'
import messages from './messages'

interface AssetHireReturnProps {
    index: number
}

const AssetHirePickup: React.FC<AssetHireReturnProps> = ({ index }) => {
    const { setValue, watch } = useFormContext()
    const [expanded, setExpanded] = useState(true)
    const { formatMessage } = useIntl()

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const customerId = watch('customerId')

    const { openModal, ModalComponent, locationOptions, locationAudits } = useLocationModal({
        onSave: (id) => {
            setValue(`bookings.${index}.waypoints.0.locationAuditId`, id)
        },
        customerId
    })

    const locationId = watch(`bookings.${index}.waypoints.0.locationAuditId`)

    const getLocationIdFromLocationAudit = (locationAuditId: number) => {
        return locationAudits.find((loc: any) => loc.id === locationAuditId)?.locationId
    }

    const { data: contacts } = useGetContactAuditByEntityIdQuery(getLocationIdFromLocationAudit(locationId), {
        skip: !locationId,
        refetchOnMountOrArgChange: true,
    })

    const getContactOptions = useCallback(() => {
        return mapContactsToOptions(contacts)
    }, [contacts])

    return (
        <>
            {ModalComponent}
            <StyledAccordion defaultExpanded sx={{ marginTop: '10px' }} onChange={handleToggleAccordion}>
                <StyledAccordionSummary
                    index={`emptyContainerReturn-content-${index}`}
                    expanded={expanded}
                    title={formatMessage(messages.assetHirePickupDetails)}
                />
                <AccordionDetails>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} md={4}>
                            <FormSelect
                                name={`bookings.${index}.waypoints.0.locationAuditId`}
                                label={formatMessage(messages.location)}
                                options={locationOptions}
                                onChange={(e) => {
                                    setValue(`bookings.${index}.waypoints.0.contactId`, null)
                                }}
                                actionLabel={
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            openModal()
                                        }}
                                        sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                                    >
                                        {formatMessage(commonMessages.addNew)}
                                    </Button>
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormDatePickerInput
                                name={`bookings.${index}.waypoints.0.scheduledDate`}
                                label={formatMessage(messages.dateAndTime)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormSelect
                                name={`bookings.${index}.waypoints.0.contactId`}
                                label={formatMessage(messages.contact)}
                                disableLabel={formatMessage(messages.noContactsAvailable)}
                                options={getContactOptions()}
                                actionLabel={
                                    <Button
                                        size='small'
                                        onClick={() => {
                                            setValue(`bookings.${index}.waypoints.0.contactId`, null)
                                        }}
                                        sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                                    >
                                        {formatMessage(messages.clearSelection)}
                                    </Button>
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='subtitle1' gutterBottom sx={{ color: '#636363' }}>
                                {formatMessage(messages.address)}
                            </Typography>
                            <Typography variant='body2' sx={{ color: '#636363' }}>
                                {getLocationAddress(locationAudits, locationId)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormInput
                                name={`bookings.${index}.waypoints.0.instructions`}
                                label={formatMessage(messages.specialInstructions)}
                                placeholder={formatMessage(commonMessages.pleaseEnter)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        </>
    )
}

export default AssetHirePickup
