import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetCurrenciesByCustomerIdQuery } from '../../../app/redux-fetch/apiShipment';
import ToggleContainer from '../../../components/Containers/ToggleContainer';
import FormInput from '../../../components/Forms/FormInput';
import messages from './messages';
import ProductForm from './ProductForm';

interface PackageContentFormProps {
    consignmentIndex: number;
    packagingIndex: number;
    isOpenPackageContent: boolean;
    handleTogglePackageContent: (action: any) => void;
}

const PackageContentForm: React.FC<PackageContentFormProps> = ({ consignmentIndex, packagingIndex, isOpenPackageContent, handleTogglePackageContent }) => {
    const { control, setValue, watch } = useFormContext();

    const { formatMessage } = useIntl();

    const { fields: products, append: appendProduct, remove: removeProduct } = useFieldArray({
        control,
        name: `bookings.${consignmentIndex}.packaging.${packagingIndex}.products`,
    });

    const customerId = watch('customerId')

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0, hideDisabled: true,
    })

    const currencyId = watch(`bookings.${consignmentIndex}.currencyId`)

    const useDeclaredValue = watch(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.useDeclaredValue`,
        false
    );

    const resetDeclaredValues = () => {
        setValue(`bookings.${consignmentIndex}.packaging.${packagingIndex}.declaredContentDescription`, undefined);
        setValue(`bookings.${consignmentIndex}.packaging.${packagingIndex}.declaredValue`, undefined);
        products.forEach((_, productIndex) => {
            removeProduct(productIndex);
        });
    };

    const handleRemoveProduct = (index: number) => {
        if (products.length === 1) return;
        removeProduct(index);
    }

    const handleToggleDeclaredValue = (checked: boolean) => {
        if (!checked) {
            appendProduct({
                productDescription: '',
                countryOfOrigin: '',
                quantity: 1,
                unitPrice: 0,
                totalValue: 0,
                currency: '',
                useProduct: false,
            });
        }
        resetDeclaredValues();
    };

    const handleAddProductIfRequired = () => {
        if (products && products.length === 0 && !useDeclaredValue)
            appendProduct({
                productDescription: '',
                countryOfOrigin: '',
                quantity: 1,
                unitPrice: 0,
                totalValue: 0,
                currency: '',
                useProduct: false,
            });
    }

    return (
        <>
            <ToggleContainer
                title={formatMessage(messages.packageContent)}
                isOpen={isOpenPackageContent}
                handleToggle={() => { handleTogglePackageContent(handleAddProductIfRequired) }}
                sx={{ mb: '16px' }}
            >
                <Grid container spacing={2} >
                    <Grid item xs={12} md={!useDeclaredValue ? 10 : 12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography sx={{ marginRight: 1, color: '#636363' }}>
                                {formatMessage(messages.specifyContent)}
                            </Typography>
                            <Controller
                                name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.useDeclaredValue`}
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <>
                                        <Switch {...field} checked={field.value}
                                            onChange={(e, checked) => {
                                                field.onChange(checked)
                                                handleToggleDeclaredValue(checked)
                                            }} />
                                    </>
                                )}
                            />
                            <Typography sx={{ marginLeft: 1, color: '#636363' }}>
                                {formatMessage(messages.declaredValue)}
                            </Typography>
                            <Box>
                                <Controller
                                    name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.isHazardous`}
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Switch {...field} checked={field.value} onChange={e => {
                                                field.onChange(e)
                                            }} />}
                                            label={formatMessage(messages.isHazardous)}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    {useDeclaredValue ? (
                        <>
                            <Grid item xs={12} md={4}>
                                <FormInput
                                    name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.declaredContentDescription`}
                                    label={formatMessage(messages.contentDescription)}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormInput
                                    name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.declaredValue`}
                                    label={formatMessage(messages.totalValue)}
                                    numberFormatProps={{
                                        decimalScale: 2,
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    type='number'
                                    unit={currencies.find(currency => currency.id === currencyId)?.localisedSymbol}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} md={2} container justifyContent="flex-end">
                                <Button
                                    startIcon={<AddCircleOutlinedIcon />}
                                    onClick={() =>
                                        appendProduct({
                                            productDescription: '',
                                            countryOfOrigin: '',
                                            quantity: 1,
                                            unitPrice: 0,
                                            totalValue: 0,
                                            currency: '',
                                            useProduct: false,
                                        })
                                    }
                                    sx={{ textTransform: 'none', marginBottom: 2 }}
                                >
                                    {formatMessage(messages.addItem)}
                                </Button>
                            </Grid>
                            {products.map((product, productIndex) => (
                                <Grid item xs={12} md={12} key={product.id}>
                                    <ProductForm
                                        consignmentIndex={consignmentIndex}
                                        packagingIndex={packagingIndex}
                                        productIndex={productIndex}
                                        removeProduct={handleRemoveProduct}
                                    />
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </ToggleContainer>
        </>
    );
};

export default PackageContentForm;
