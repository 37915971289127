import {
    InputBaseComponentProps,
} from '@mui/material';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface NumberFormatCustomProps extends InputBaseComponentProps {
    allowOnlyIntegers?: boolean;
    minValue?: number;
    decimalScale?: number;
    allowNegative?: boolean;
}

export const NumberFormatCustom = React.forwardRef<
    HTMLInputElement,
    NumericFormatProps & NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
    const { onChange, name, allowOnlyIntegers = false, minValue, decimalScale, allowNegative = false, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            thousandSeparator={true}
            valueIsNumericString
            decimalScale={allowOnlyIntegers ? 0 : decimalScale ? decimalScale : 6}
            fixedDecimalScale={!allowOnlyIntegers}
            allowNegative={allowNegative}
            isAllowed={(values) => {
                const { floatValue, formattedValue } = values;
                if (formattedValue === '' || formattedValue === '-' || formattedValue === '.') {
                    return true;
                }

                if (floatValue === undefined) {
                    return true;
                }

                if (minValue !== undefined && floatValue < minValue) {
                    return false;
                }
                return true;
            }}
            onValueChange={(values) => {
                onChange?.({
                    target: {
                        name: name as string,
                        value: values.floatValue ?? '',
                    },
                });
            }}
        />
    );
});
