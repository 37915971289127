import { Box, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { InputHeader } from '../Headers/styles';
import { NumberFormatCustom, NumberFormatCustomProps } from './NumberFormatCustom';

type InfoDisplayProps = {
    label: string;
    value?: string | number;
    unit?: string;
    actionLabel?: React.ReactNode;
    numberFormatProps?: NumberFormatCustomProps;
};

const InfoDisplay: React.FC<InfoDisplayProps> = ({
    label,
    value,
    unit,
    actionLabel,
    numberFormatProps,
}) => {
    return (
        <Box mb={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <InputHeader gutterBottom>{label}</InputHeader>
                {actionLabel && actionLabel}
            </Box>
            <TextField
                variant="outlined"
                fullWidth
                value={value ?? ''}
                InputProps={{
                    readOnly: true,
                    inputComponent: numberFormatProps ? NumberFormatCustom : undefined,
                    endAdornment: unit ? (
                        <InputAdornment position="end">{unit}</InputAdornment>
                    ) : null,
                    inputProps: {
                        ...numberFormatProps,
                    },
                }}
            />
        </Box>
    );
};

export default InfoDisplay;
