import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway'
import FlightIcon from '@mui/icons-material/Flight'
import MultipleStopIcon from '@mui/icons-material/MultipleStop'
import { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

interface TransportIconProps {
  id: number
}

const getTransportIcon = (id: number): React.ReactElement<SvgIconProps> => {
  switch (id) {
    case 0:
      return <MultipleStopIcon />
    case 1:
      return <FlightIcon />
    case 2:
      return <DirectionsBoatIcon />
    case 3:
      return <DirectionsCarIcon />
    case 4:
      return <DirectionsRailwayIcon />
    default:
      return <MultipleStopIcon />
  }
}

const TransportIcon: React.FC<TransportIconProps> = ({ id }) => {
  return <>{getTransportIcon(id)}</>
}

export default TransportIcon
