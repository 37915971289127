import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery';
import { convertToLabelValue } from '../../../app/utils';
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer';
import FormInput from '../../../components/Forms/FormInput';
import FormSelect from '../../../components/Forms/FormSelect';
import { PageHeader } from '../../../components/Headers/styles';
import commonMessages from '../../../components/Messages/commonMessages';
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../../components/Typographies/LabelItem';
import { useOrderFormContext } from '../context/OrderFormContext';
import messages from './messages';

interface ShipmentInformationProps {
    isEdit: boolean;
}

function mapAddressAuditToAddress(addressAudit: any): any {
    if (!addressAudit) return null;
    return {
        addressLine1: addressAudit.addressLine1,
        addressLine2: addressAudit.addressLine2,
        city: addressAudit.city,
        state: addressAudit.state,
        country: addressAudit.country,
        postalCode: addressAudit.postalCode,
    };
}

function mapLocationAuditToLocation(audit: any): any {
    return {
        id: audit.id,
        tenantId: audit.tenantId,
        customerId: audit.customerId,
        name: audit.name,
        latitude: audit.latitude,
        longitude: audit.longitude,
        locationTypeId: audit.locationTypeId,
        address: mapAddressAuditToAddress(audit.address),
        contacts: audit.contacts,
        geofence: audit.geofence,
        defaultTransportModeId: audit.defaultTransportModeId,
        deleted: audit.deleted,
    };
}

const ShipmentInformation: React.FC<ShipmentInformationProps> = ({ isEdit }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { watch } = useFormContext();

    const { currentPage } = useOrderFormContext();
    const { formatMessage } = useIntl();

    const customer = watch('customerId');
    const customerReferenceNumber = watch('customerReferenceNumber');
    const description = watch('description');
    const shipmentNumber = watch('shipmentNumber');

    const { tenantId, isTenantUser, isCustomerUser, customerId } = useLoggedInUser();

    const { data: customers = [] } = useGetCustomersQuery(tenantId);


    const getCustomerName = (customerId: number) => {
        return customers.find((customer) => customer.id === customerId)?.name;
    };

    if (currentPage !== 1) {
        return (
            <BoxWhiteContainer sx={{ mb: '32px' }}>
                <Grid container>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <LabelItem>{formatMessage(messages.shipmentInformation)}</LabelItem>
                    </Grid>
                    {
                        isTenantUser ? (<>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.customer)}
                                    value={getCustomerName(customer) || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.customerReferenceNumber)}
                                    value={customerReferenceNumber || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.shipmentNumber)}
                                    value={shipmentNumber || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item md={3}></Grid>
                            <Grid item xs={12} md={9} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.description)}
                                    value={description || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                        </>) : (
                            <>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <InfoDisplayItem
                                        label={formatMessage(messages.description)}
                                        value={description || 'N/A'}
                                        isSmallScreen={isSmallScreen}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <InfoDisplayItem
                                        label={formatMessage(messages.customerReferenceNumber)}
                                        value={customerReferenceNumber || 'N/A'}
                                        isSmallScreen={isSmallScreen}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <InfoDisplayItem
                                        label={formatMessage(messages.shipmentNumber)}
                                        value={shipmentNumber || 'N/A'}
                                        isSmallScreen={isSmallScreen}
                                    />
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </BoxWhiteContainer>
        );
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <PageHeader>
                        {formatMessage(messages.shipmentInformation)}
                    </PageHeader>
                </Grid>
                {isTenantUser && (<Grid item xs={12} md={3}>
                    <FormSelect
                        name="customerId"
                        label={formatMessage(messages.customer)}
                        options={convertToLabelValue(customers)}
                        disabled={isCustomerUser}
                        fullWidth
                    />
                </Grid>)}
                <Grid item xs={12} md={isTenantUser ? 5 : 6}>
                    <FormInput
                        name="description"
                        label={formatMessage(messages.description)}
                        placeholder={formatMessage(commonMessages.pleaseEnter)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={isTenantUser ? 2 : 3}>
                    <FormInput
                        name="customerReferenceNumber"
                        label={formatMessage(messages.customerReferenceNumber)}
                        placeholder={formatMessage(commonMessages.pleaseEnter)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={isTenantUser ? 2 : 3}>
                    <FormInput
                        name="shipmentNumber"
                        label={formatMessage(messages.shipmentNumber)}
                        placeholder={formatMessage(messages.autoGenerated)}
                        fullWidth
                        disabled
                        helperText={!isEdit ? formatMessage(messages.autoGenerated) : ''}
                    />
                </Grid>
            </Grid>
            // <Box display="flex" flexWrap="wrap" gap={2} sx={{ marginBottom: 2 }}>
            //     <Box
            //         flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
            //         minWidth={isSmallScreen ? '100%' : '200px'}
            //     >

            //     </Box>
            //     <Box
            //         flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
            //         minWidth={isSmallScreen ? '100%' : '200px'}
            //     >

            //     </Box>
            //     <Box
            //         flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
            //         minWidth={isSmallScreen ? '100%' : '200px'}
            //     >

            //     </Box>
            //     <Box
            //         flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
            //         minWidth={isSmallScreen ? '100%' : '200px'}
            //     >

            //     </Box>
            // </Box>
        );
    }
};

export default ShipmentInformation;
