import { Box, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../app/hooks'
import { useGetShipmentByIdQuery } from '../../../../app/redux-fetch/apiShipment'
import { TableColumn, TableItem } from '../../../../components/Headers/styles'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'

const ViewShipmentTabHeader = () => {
  const theme = useTheme()
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId
  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId

  const {
    data: shipment,
    isFetching: shipmentSelectorFetching,
    isSuccess: shipmentLoaded,
  } = useGetShipmentByIdQuery({
    shipmentId: Number(id),
    tenantId,
  })

  return (
    <Box display='flex' flexDirection='column' sx={{ pt: '32px' }}>
      <Box display='flex'>
        <TableTitleTypography>Shipment Information</TableTitleTypography>
      </Box>
      <Box display='flex'>
        <TableColumn>
          <Typography variant='body1'>Status</Typography>
        </TableColumn>
        {isTenantUser && (
          <TableColumn>
            <Typography variant='body1'>Customer</Typography>
          </TableColumn>
        )}
        <TableColumn>
          <Typography variant='body1'>Customer Reference Number</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Cargo Description</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Last Status Change</Typography>
        </TableColumn>
      </Box>
      <Box display='flex'>
        <TableItem>
          <Typography>{shipment.status}</Typography>
        </TableItem>
        {isTenantUser && (
          <TableItem>
            <Typography>{shipment?.customer?.name}</Typography>
          </TableItem>
        )}
        <TableItem>
          <Typography>{shipment?.customerReferenceNumber}</Typography>
        </TableItem>
        <TableItem>
          <Typography>{shipment?.description}</Typography>
        </TableItem>
        <TableItem>
          <Typography>{shipment && shipment.lastStatusChangeDate && dayjs(shipment.lastStatusChangeDate).format('DD/MM/YYYY')}</Typography>
        </TableItem>
      </Box>
    </Box>
  )
}

export default ViewShipmentTabHeader
