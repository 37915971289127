import {
    Box,
    Button,
    createTheme,
    Grid,
    Tab,
    Tabs,
    ThemeProvider,
    useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useNotificationStack } from '../../../app/hooks/useNotificationStack';
import {
    useAcceptOrderByTenantIdMutation,
    useGetAssetHireByIdQuery,
    useRecallOrderMutation,
    useRejectOrderByTenantIdMutation,
    useResetReviewOrderByTenantIdMutation,
    useReviewOrderByTenantIdMutation,
    useSubmitOrderMutation
} from '../../../app/redux-fetch/apiShipment';
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender';
import rootEnum from '../../../components/Routes/rootEnum';
import SpinnerBlock from '../../../components/Spinner/SpinnerBlock';

import TooltipButton from '../../../components/Buttons/TooltipButton';
import ActionButton from '../../../components/Forms/ActionButton';
import TabPanel from '../../../components/TabPanel/TabPanel';
import { OrderStatuses } from '../../../types/common';
import Note from '../../Notes/Note';
import AssetHireSummary from './AssetHireSummary';
import messages from './messages';
import ViewAssetHireHeader from './ViewAssetHireHeader';

const ViewAssetHire = (): ReactElement => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { id } = useParams();
    const orderId = Number(id);

    const theme = useTheme();
    const customTheme = createTheme(theme, {
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.secondary.main,
                            color: 'white',
                            borderRadius: '5px',
                        },
                    },
                },
            },
        },
    });

    const {
        tenantId,
        loggedInUser,
        isTenantUser,
        isCustomerUser
    } = useLoggedInUser()

    const loggedInUserId = loggedInUser.id;

    const [activeTab, setActiveTab] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUnderReview, setIsUnderReview] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(0);
    const [underReviewUserId, setUnderReviewUserId] = useState<number | undefined>();

    const { enqueueSuccess, enqueueFailure } = useNotificationStack();

    const [submitOrder] = useSubmitOrderMutation();
    const [acceptOrder] = useAcceptOrderByTenantIdMutation();
    const [rejectOrder] = useRejectOrderByTenantIdMutation();
    const [recallOrder] = useRecallOrderMutation();
    const [reviewOrder] = useReviewOrderByTenantIdMutation();
    const [resetReviewOrder] = useResetReviewOrderByTenantIdMutation();

    const {
        data: order,
        isFetching: assetHireFetching,
        isSuccess: shipmentLoaded,
        refetch,
    } = useGetAssetHireByIdQuery({
        tenantId: tenantId,
        id: Number(id),
    }, {
        refetchOnMountOrArgChange: true,
    });

    const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        if (order) {
            if (order.underReviewUntil && order.underReviewByUserId) {
                const diffTime = Math.max(
                    0,
                    Math.floor((new Date(order.underReviewUntil).getTime() - Date.now()) / 1000)
                );
                if (diffTime > 0) {
                    setCountdown(diffTime);
                    setIsUnderReview(true);
                    setUnderReviewUserId(order.underReviewByUserId);
                } else {
                    setIsUnderReview(false);
                    setUnderReviewUserId(undefined);
                    setCountdown(0);
                }
            } else {
                setIsUnderReview(false);
                setUnderReviewUserId(undefined);
                setCountdown(0);
            }
        }
    }, [order]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (isUnderReview && underReviewUserId === loggedInUserId) {
            handleResetReview();
        }
    }, [countdown, isUnderReview, underReviewUserId, loggedInUserId]);

    const handleLockForReview = useCallback(async () => {
        try {
            setIsSubmitting(true);
            await reviewOrder({ tenantId, id: orderId });
            setUnderReviewUserId(loggedInUserId);
            setIsUnderReview(true);
            setCountdown(10 * 60);
            enqueueSuccess(messages.assetHireUpdateSuccess);
            await refetch();
        } catch (e) {
            enqueueFailure(messages.assetHireUpdateError);
        } finally {
            setIsSubmitting(false);
        }
    }, [
        tenantId,
        id,
        loggedInUserId,
        refetch,
        reviewOrder,
        enqueueSuccess,
        enqueueFailure,
    ]);

    const handleResetReview = useCallback(async () => {
        try {
            await resetReviewOrder({ tenantId, id: orderId });
            setIsUnderReview(false);
            setUnderReviewUserId(undefined);
            setCountdown(0);
            await refetch();
        } catch (e) {
            enqueueFailure(messages.assetHireUpdateError);
        }
    }, [tenantId, id, resetReviewOrder, refetch, enqueueFailure]);

    const handleBackToList = useCallback(async () => {
        if (underReviewUserId === loggedInUserId && isUnderReview) {
            await handleResetReview();
        }
        navigate(rootEnum.ASSET_HIRES);
    }, [
        navigate,
        underReviewUserId,
        loggedInUserId,
        isUnderReview,
        handleResetReview,
    ]);

    const isUnderReviewByDifferentUser = useCallback(() => {
        return isUnderReview && underReviewUserId !== loggedInUserId;
    }, [isUnderReview, underReviewUserId, loggedInUserId]);

    const isUnderReviewByCurrentUser = useCallback(() => {
        return isUnderReview && underReviewUserId === loggedInUserId;
    }, [isUnderReview, underReviewUserId, loggedInUserId]);

    const handleAccept = async () => {
        try {
            if (!isSubmitted) {
                await submitOrder({ tenantId, id: order?.id }).unwrap();
            }
            await acceptOrder({ tenantId, id: order?.id }).unwrap();
            enqueueSuccess(messages.assetHireAccepted);
            navigate('/assetHire');
        } catch (error) {
            enqueueFailure(messages.assetHireAcceptFailed);
        }
    };

    const handleSubmit = async () => {
        try {
            await submitOrder({ tenantId, id: order?.id }).unwrap();
            enqueueSuccess(messages.assetHireSubmitted);
            navigate('/assetHire');
        } catch (error) {
            enqueueFailure(messages.assetHireSubmitFailed);
        }
    };

    const handleReject = async () => {
        try {
            await rejectOrder({
                tenantId,
                id: order?.id,
            }).unwrap();
            enqueueSuccess(messages.assetHireRejected);
            navigate('/assetHire');
        } catch (error) {
            enqueueFailure(messages.assetHireRejectFailed);
        }
    };

    const handleRecall = async () => {
        try {
            await recallOrder({
                tenantId,
                id: order?.id,
            }).unwrap();
            enqueueSuccess(messages.assetHireRecalled);
            navigate('/assetHire');
        } catch (error) {
            enqueueFailure(messages.assetHireRecallFailed);
        }
    };

    if (!shipmentLoaded || assetHireFetching) return <SpinnerBlock />;

    const isSubmitted = order.status === OrderStatuses.Submitted || order.status === OrderStatuses.UnderReview;
    const isAccepted = order.status === OrderStatuses.Accepted;

    const isBookingRequired = (!order.bookings || order.bookings?.length === 0)

    return (
        <Grid container spacing={2} pt={2}>
            <Grid container spacing={1} pt={2} pb={2} pl={'10px'}>
                <Grid item xs>
                    <Box display='flex' justifyContent='flex-start'
                        alignContent={'center'}>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={handleBackToList}
                        >
                            {formatMessage(messages.backToAssetHiresBtn)}
                        </Button>
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <ConditionalRender
                                condition={isTenantUser && isSubmitted && !isAccepted}
                            >
                                <ActionButton
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleLockForReview}
                                    sx={{
                                        mb: '24px'
                                    }}
                                    actionLabel={
                                        isUnderReviewByCurrentUser() && countdown > 0 ? (
                                            <Button
                                                size='small'
                                                onClick={async () => {
                                                    await handleResetReview();
                                                }}
                                                sx={{ textTransform: 'none', p: 0, mb: '4px' }}
                                            >
                                                {formatMessage(messages.unlock)}
                                            </Button>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    disabled={isUnderReview || isSubmitting}
                                >
                                    {countdown && countdown > 0
                                        ? formatMessage(messages.countdownTitle, {
                                            value: `[${dayjs()
                                                .hour(0)
                                                .minute(0)
                                                .second(countdown)
                                                .format('mm:ss')}]`,
                                        })
                                        : formatMessage(messages.lockForReview)}
                                </ActionButton>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <ConditionalRender
                                condition={isTenantUser && isSubmitted && !isAccepted}
                            >
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleReject}
                                    disabled={isUnderReviewByDifferentUser() || isSubmitting}
                                >
                                    {formatMessage(messages.buttonReject)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>

                            <ConditionalRender condition={isTenantUser && !isAccepted}>
                                <TooltipButton
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleAccept}
                                    disabled={isUnderReviewByDifferentUser() || isSubmitting || isBookingRequired}
                                    showTooltip={isBookingRequired}
                                    tooltipProps={{
                                        placement: 'top',
                                        title: formatMessage(messages.noBooking),
                                        children: (<></>)
                                    }}
                                >
                                    {formatMessage(isSubmitted ? messages.acceptAssetHire : messages.submitAndAccept)}
                                </TooltipButton>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <ConditionalRender
                                condition={isCustomerUser && isSubmitted && !isAccepted}
                            >
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleRecall}
                                    disabled={isUnderReview || isSubmitting}
                                >
                                    {formatMessage(messages.buttonRecall)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                alignContent={'center'}
                                gap={1}
                            >
                                <ConditionalRender condition={!isSubmitted && !isAccepted}>
                                    <TooltipButton
                                        variant='contained'
                                        onClick={handleSubmit}
                                        disabled={isUnderReview || isSubmitting || isBookingRequired}
                                        showTooltip={isBookingRequired}
                                        tooltipProps={{
                                            placement: 'top',
                                            title: formatMessage(messages.noBooking),
                                            children: (<></>)
                                        }}
                                    >
                                        {formatMessage(messages.submitAssetHire)}
                                    </TooltipButton>
                                </ConditionalRender>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ViewAssetHireHeader />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%', pt: '28px', pb: '28px' }}>
                    <ThemeProvider theme={customTheme}>
                        <Tabs
                            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                            value={activeTab}
                            onChange={handleChange}
                            centered
                            variant="standard"
                            scrollButtons={false}
                            aria-label="Asset Hire View Tabs"
                        >
                            <Tab label="Details" />
                            <Tab label="Notes" />
                        </Tabs>
                    </ThemeProvider>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <Grid item xs={12}>
                        <AssetHireSummary data={order} />
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <Grid item xs={12}>
                        <Note />
                    </Grid>
                </TabPanel>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid >
    );
};

export default ViewAssetHire;
