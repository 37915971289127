import { AccordionDetails, Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetCurrenciesByCustomerIdQuery, useGetLocationAuditsQuery, useGetLocationsQuery } from '../../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../../app/utils';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import FormInput from '../../../components/Forms/FormInput';
import FormSelect from '../../../components/Forms/FormSelect';
import commonMessages from '../../../components/Messages/commonMessages';
import messages from './messages';

interface ConsignmentInformationFormProps {
    index: number
}


function mapAddressAuditToAddress(addressAudit: any): any {
    if (!addressAudit) return null;
    return {
        addressLine1: addressAudit.addressLine1,
        addressLine2: addressAudit.addressLine2,
        city: addressAudit.city,
        state: addressAudit.state,
        country: addressAudit.country,
        postalCode: addressAudit.postalCode,
    };
}

function mapLocationAuditToLocation(audit: any): any {
    return {
        id: audit.id,
        tenantId: audit.tenantId,
        customerId: audit.customerId,
        name: audit.name,
        latitude: audit.latitude,
        longitude: audit.longitude,
        locationTypeId: audit.locationTypeId,
        address: mapAddressAuditToAddress(audit.address),
        contacts: audit.contacts,
        geofence: audit.geofence,
        defaultTransportModeId: audit.defaultTransportModeId,
        deleted: audit.deleted,
    };
}

const ConsignmentInformationForm: React.FC<ConsignmentInformationFormProps> = ({ index }: ConsignmentInformationFormProps) => {

    const { watch } = useFormContext();
    const { formatMessage } = useIntl();
    const [expanded, setExpanded] = useState(true);

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const customerId = watch('customerId')
    const tenantId = watch('tenantId')

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0, hideDisabled: true,
    })

    const { data: locations = [] } = useGetLocationsQuery({ tenantId, customerId });
    const { data: locationAudits = [] } = useGetLocationAuditsQuery({ tenantId, customerId });

    const mergedLocations = useMemo(() => {
        const locationAuditMap = new Map<number, any>(
            locationAudits.map((audit: any) => [audit.locationId, audit])
        )

        return locations
            .map((location: any) => {
                const audit = locationAuditMap.get(location.id);
                if (audit) {
                    const auditedLocation = mapLocationAuditToLocation(audit);
                    return {
                        ...location,
                        ...auditedLocation,
                    };
                }
                return location;
            })
            .filter((location: any) => {
                return location.locationTypeId === 255
            });
    }, [locations, locationAudits])

    return (
        <StyledAccordion sx={{
            '&:before': {
                display: 'none',
            },
        }} expanded={expanded}
            onChange={handleToggleAccordion}>
            <StyledAccordionSummary
                index={`consignment-info-content-${index}`}
                title='Consignment Information'
                expanded={expanded}
            >
            </StyledAccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormSelect
                            name={`bookings.${index}.shipperId`}
                            label={formatMessage(messages.shipper)}
                            options={convertToLabelValue(mergedLocations)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormSelect
                            name={`bookings.${index}.currencyId`}
                            label={formatMessage(messages.currency)}
                            options={convertToLabelValue(currencies.map((currency) => ({ id: currency.id, name: `${currency.iso3} - ${currency.localisedSymbol}`, })))}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormInput
                            name={`bookings.${index}.referenceNumber`}
                            label={formatMessage(messages.consignmentReferenceNumber)}
                            placeholder={formatMessage(commonMessages.pleaseEnter)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <FormInput
                            name={`bookings.${index}.description`}
                            label={formatMessage(messages.consignmentDescription)}
                            placeholder={formatMessage(messages.consignmentDescription)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormInput
                            name={`bookings.${index}.trackingNumber`}
                            label={formatMessage(messages.consignmentTrackingNumber)}
                            placeholder={formatMessage(messages.systemGenerated)}
                            disabled={true}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </StyledAccordion >
    )
}

export default ConsignmentInformationForm;