import { Box, Grid } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery';
import { useGetAllLoadTypesQuery, useGetCountriesQuery, useGetCurrenciesByCustomerIdQuery, useGetLocationAuditsQuery } from '../../../app/redux-fetch/apiShipment';
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer';
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2';
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem';
import { LabelItem, LabelWarningItem } from '../../../components/Typographies/LabelItem';
import { TableSubTitleTypography } from '../../../components/Typographies/TableTitleTypography';
import { MRTColumnDef } from '../../../types/common';
import { formatNumber } from '../../utils';
import messages from './messages';
import ShipmentDiagram from './ShipmentDiagram';
import { IShipmentFormInput } from './ShipmentForm';
import { getCustomerName } from './utils';

type ShipmentSummaryProps = {
    data: IShipmentFormInput
}

const ShipmentSummary: React.FC<ShipmentSummaryProps> = ({ data }) => {
    const isSmallScreen = false;

    const { formatMessage } = useIntl()

    const { data: customers = [] } = useGetCustomersQuery(data.tenantId)

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: data.customerId ?? 0, hideDisabled: true,
    })

    const { data: locations = [] } = useGetLocationAuditsQuery({
        tenantId: data.tenantId,
        customerId: undefined,
    });

    const { data: countries = [] } = useGetCountriesQuery()

    const { data: packagingTypes = [] } = useGetAllLoadTypesQuery();

    const getCountryName = (countryId?: number) => {
        if (countryId === undefined) return 'N/A';
        const country = countries.find((country: any) => country.id === countryId);
        return country?.name || 'N/A';
    }

    const getCurrencyLabel = (currencyId?: number) => {
        if (currencyId === undefined) return 'N/A';
        const currency = currencies.find((curr) => curr.id === currencyId);
        return currency?.iso3 || 'N/A';
    }

    const getPackagingTypeName = (typeId?: number) => {
        if (typeId === undefined) return 'N/A';
        const type = packagingTypes.find((packagingType: any) => packagingType.id === typeId);
        return type?.name || 'N/A';
    }

    const getLocationName = (locationId?: number) => {
        if (locationId === undefined) return 'N/A';
        const location = locations.find((loc: any) => loc.id === locationId);
        return location?.name || 'N/A';
    }

    const getProductColumns = (relatedBooking: any): MRTColumnDef<any>[] => [
        {
            accessorKey: 'productDescription',
            header: formatMessage(messages.description),
        },
        {
            accessorKey: 'countryOfOriginId',
            header: formatMessage(messages.countryOfOrigin),
            Cell: ({ cell }: any) => {
                return getCountryName(cell.getValue());
            },
        },
        {
            accessorKey: 'quantity',
            header: formatMessage(messages.quantity),
        },
        {
            accessorKey: 'unitPrice',
            header: formatMessage(messages.unitPrice),
            Cell: ({ cell }: any) => {
                const unitPrice = cell.row.original.unitPrice;
                return `${unitPrice} ${getCurrencyLabel(relatedBooking?.currencyId)}`;
            },
        },
        {
            accessorKey: 'totalPrice',
            header: formatMessage(messages.totalValue),
            Cell: ({ cell }: any) => {
                const quantity = cell.row.original.quantity;
                const unitPrice = cell.row.original.unitPrice;

                return `${quantity * unitPrice} ${getCurrencyLabel(relatedBooking?.currencyId)}`;
            },
        },
    ];

    return (
        <Box>
            <BoxWhiteContainer sx={{ mb: '32px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <LabelItem >{formatMessage(messages.customerInformation)}</LabelItem>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InfoDisplayItem
                            label={formatMessage(messages.customer)}
                            value={getCustomerName(customers, data.customerId) || 'N/A'}
                            isSmallScreen={isSmallScreen}

                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InfoDisplayItem
                            label={formatMessage(messages.customerReferenceNumber)}
                            value={data.customerReferenceNumber || 'N/A'}
                            isSmallScreen={isSmallScreen}
                        />
                    </Grid>
                </Grid>
            </BoxWhiteContainer>
            {data.bookings &&
                data.bookings.map((consignment: any, cIndex: number) => (
                    <BoxWhiteContainer key={cIndex} sx={{ mb: '16px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <LabelItem
                                    padding={'0px'}
                                >{formatMessage(messages.consignmentItem, { idx: cIndex + 1 })}
                                </LabelItem>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.consignmentReferenceNumber)}
                                    value={
                                        consignment.referenceNumber || 'N/A'
                                    }
                                    isSmallScreen={isSmallScreen}
                                    sx={{
                                        pb: '0px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.consignmentTrackingNumber)}
                                    value={
                                        consignment.trackingNumber || 'N/A'
                                    }
                                    isSmallScreen={isSmallScreen}
                                    sx={{
                                        pb: '0px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.shipper)}
                                    value={
                                        getLocationName(consignment.shipperId) || 'N/A'
                                    }
                                    isSmallScreen={isSmallScreen}
                                    sx={{
                                        pb: '0px'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <LabelItem
                                    padding={'0px'}
                                >{formatMessage(messages.shipFromDeliverTo)}</LabelItem>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.shipFrom)}
                                    value={getLocationName(consignment.shipFrom?.locationId)}
                                    isSmallScreen={isSmallScreen}
                                    sx={{
                                        pb: '0px'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.deliverTo)}
                                    value={getLocationName(consignment.deliverTo?.locationId)}
                                    isSmallScreen={isSmallScreen}
                                    sx={{
                                        pb: '0px'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {consignment.packaging &&
                            consignment.packaging.map((packaging: any, pIndex: number) => {
                                const columns = getProductColumns(consignment);
                                return (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}>
                                                    <LabelItem
                                                        padding={'0px'}
                                                    >{formatMessage(messages.loadItem, { idx: pIndex + 1 })}</LabelItem>
                                                    {packaging.temperatureControlled && (<LabelWarningItem>{formatMessage(messages.temperatureControlled)}</LabelWarningItem>)}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={3}>

                                                <InfoDisplayItem
                                                    label={formatMessage(messages.loadType)}
                                                    value={getPackagingTypeName(packaging.packagingTypeId)}
                                                    isSmallScreen={isSmallScreen}
                                                    sx={{
                                                        pb: '0px'
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InfoDisplayItem
                                                    label={formatMessage(messages.noOfIdenticalLoads)}
                                                    value={packaging.numberOfPackages || 'N/A'}
                                                    isSmallScreen={isSmallScreen}
                                                    sx={{
                                                        pb: '0px'
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Box sx={{
                                                    pl: '16px',
                                                }}>
                                                    {packaging.products.length > 0 ? (
                                                        <MRTDataGridv2
                                                            state={{ isLoading: false }}
                                                            componentPadding='0px 0px 8px 0px'
                                                            leftHeadingComponent={
                                                                <Box sx={{ display: 'inline-flex' }}>
                                                                    <TableSubTitleTypography>{formatMessage(messages.products)}</TableSubTitleTypography>
                                                                </Box>
                                                            }
                                                            columns={columns}
                                                            data={packaging.products}
                                                            enableColumnActions={false}
                                                            enableColumnFilters={false}
                                                            enablePagination={false}
                                                            enableSorting={false}
                                                            enableBottomToolbar={false}
                                                            enableTopToolbar={false}
                                                        />) : (<>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} md={3}>
                                                                    <InfoDisplayItem
                                                                        label={formatMessage(messages.declaredValue)}
                                                                        value={`${packaging.declaredValue ? formatNumber(packaging.declaredValue) : 'N/A'}  ${getCurrencyLabel(consignment?.currencyId) || 'N/A'}`}
                                                                        isSmallScreen={isSmallScreen}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={9} sx={{
                                                                    ml: '-20px'
                                                                }}>
                                                                    <InfoDisplayItem
                                                                        label={formatMessage(messages.contentDescription)}
                                                                        value={packaging.declaredContentDescription}
                                                                        isSmallScreen={isSmallScreen}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid >
                                    </>)
                            })}
                    </BoxWhiteContainer>
                ))
            }
            <ShipmentDiagram shipmentData={data as IShipmentFormInput} />
        </Box >
    );
};

export default ShipmentSummary;

