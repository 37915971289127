import { AccordionDetails, Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetAssetConfigurationsQuery } from '../../../app/redux-fetch/apiAssets';
import { convertToLabelValue } from '../../../app/utils';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import FormInput from '../../../components/Forms/FormInput';
import FormSelect from '../../../components/Forms/FormSelect';
import commonMessages from '../../../components/Messages/commonMessages';
import messages from './messages';

interface AssetHireInformationFormProps {
    index: number
}

const AssetHireInformationForm: React.FC<AssetHireInformationFormProps> = ({ index }: AssetHireInformationFormProps) => {

    const { watch } = useFormContext();
    const { formatMessage } = useIntl();
    const [expanded, setExpanded] = useState(true);

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const tenantId = watch('tenantId')

    const { data: assetConfigurations = [], isSuccess: assetConfigurationLoaded } = useGetAssetConfigurationsQuery({ tenantId })

    const assetConfigurationsOptions = useMemo(() => {
        return convertToLabelValue(assetConfigurations)
    }, [assetConfigurations])

    return (
        <StyledAccordion sx={{
            '&:before': {
                display: 'none',
            },
        }} expanded={expanded}
            onChange={handleToggleAccordion}>
            <StyledAccordionSummary
                index={`asset-hire-info-content-${index}`}
                title={formatMessage(messages.assetHireDetails)}
                expanded={expanded}
            >
            </StyledAccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <FormSelect
                            name={`bookings.${index}.assetConfigurationId`}
                            label={formatMessage(messages.assetConfiguration)}
                            options={assetConfigurationsOptions}
                            placeholder={formatMessage(commonMessages.pleaseEnter)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <FormInput
                            name={`bookings.${index}.quantity`}
                            label={formatMessage(messages.quantity)}
                            placeholder={formatMessage(messages.quantity)}
                            fullWidth
                            type='number'
                            numberFormatProps={{
                                allowOnlyIntegers: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </StyledAccordion >
    )
}

export default AssetHireInformationForm;