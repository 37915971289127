import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { AccordionDetails, Button, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetUserTypesQuery } from '../../../app/redux-fetch/apiQuery';
import { convertToLabelValue } from '../../../app/utils';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import FormSelect from '../../../components/Forms/FormSelect';
import { AccordionHeader } from '../../../components/Headers/styles';
import commonMessages from '../../../components/Messages/commonMessages';
import messages from './messages';

interface WorkforceDetailsProps {
    index: number;
}

const WorkforceDetails: React.FC<WorkforceDetailsProps> = ({ index }) => {
    const [expanded, setExpanded] = useState(true)
    const { control, watch } = useFormContext();

    const { formatMessage } = useIntl()

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const tenantId = watch('tenantId')

    const { data: workforcesTypes = [] } = useGetUserTypesQuery({
        tenantId,
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: `bookings.${index}.workforce`
    });

    const addWorkforceItem = () => {
        append({});
    };

    const removeWorkforceItem = (index: number) => {
        remove(index);
    };

    const workforceOptions = useMemo(() => {
        return convertToLabelValue(workforcesTypes)
    }, [workforcesTypes])

    return (
        <>
            <StyledAccordion defaultExpanded sx={{ marginTop: '10px' }} onChange={handleToggleAccordion}>
                <StyledAccordionSummary
                    index={`emptyContainerReturn-content-${index}`}
                    expanded={expanded}
                    title={formatMessage(messages.workforceDetails)}
                >
                    <AccordionHeader sx={{ flexGrow: 1 }}>
                        {formatMessage(messages.workforceDetails)}
                    </AccordionHeader>
                    <Button
                        startIcon={<AddCircleOutlinedIcon />}
                        size='small'
                        onClick={(event) => {
                            event.stopPropagation();
                            addWorkforceItem();
                        }}
                        sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                        {formatMessage(messages.addWorkforceType)}
                    </Button>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {fields.map((field, idx) => (
                            <>
                                <Grid item xs={12} md={4} key={field.id}>
                                    <FormSelect
                                        name={`bookings.${index}.workforce.${idx}.workforceTypeId`}
                                        label={formatMessage(messages.workforceType)}
                                        options={workforceOptions}
                                        fullWidth
                                        actionLabel={
                                            <Button
                                                size='small'
                                                onClick={() => removeWorkforceItem(idx)}
                                                sx={{ textTransform: 'none', p: 0, mb: '6px', color: 'red' }}
                                            >
                                                {formatMessage(commonMessages.remove)}
                                            </Button>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} key={`quantity-${field.id}`}>
                                    <FormSelect
                                        name={`bookings.${index}.workforce.${idx}.quantity`}
                                        label={formatMessage(messages.quantity)}
                                        options={
                                            [
                                                { label: '1', value: 1 },
                                                { label: '2', value: 2 },
                                                { label: '3', value: 3 },
                                                { label: '4', value: 4 },
                                                { label: '5', value: 5 },
                                                { label: '6', value: 6 },
                                                { label: '7', value: 7 },
                                                { label: '8', value: 8 },
                                                { label: '9', value: 9 },
                                                { label: '10', value: 10 }
                                            ]}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} key={`hours-${field.id}`}>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        </>
    );
};

export default WorkforceDetails;
