import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; // To generate a new GUID
import { useAppSelector } from '../../app/hooks';
import { useGetTemperatureDataMutation } from '../../app/redux-fetch/apiQuery';
import { useGetShipmentByIdQuery } from '../../app/redux-fetch/apiShipment';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import SpinnerBoxBlock from '../../components/Spinner/SpinnerBoxBlock';
import { fetchLoggedInUserSelector } from '../selectors';
import { ILoggedInUser } from '../types';
import ColoredLoadDropdown from './ColoredLoadDropdown';
import LoadTemperatureCard from './LoadTemperatureCard';
import LoadTemperatureReadTable from './LoadTemperatureReadTable';
import { TemperatureTabTile } from './TemperatureTabTile';
import { LoadIdAggregatedTemperatureData, PreparedChartData, TemperatureChartProps, TemperatureSeries } from './types';



function aggregateByLoadId(data: TemperatureSeries[]): LoadIdAggregatedTemperatureData {
    return data.reduce((acc, curr) => {
        if (!acc[curr.loadId]) {
            acc[curr.loadId] = {
                externalInsertionDate: dayjs(curr.externalInsertionDate).format('DD MMMM'),
                sensor1: curr.sensor1,
                setPointSum: curr.setPoint,
                returnAirSum: curr.returnAir,
                dischargeAirSum: curr.dischargeAir
            };
        } else {
            acc[curr.loadId].setPointSum += curr.setPoint;
            acc[curr.loadId].returnAirSum += curr.returnAir;
            acc[curr.loadId].dischargeAirSum += curr.dischargeAir;
        }
        return acc;
    }, {} as LoadIdAggregatedTemperatureData);
}

function getAverageTemperature(data: TemperatureSeries[], loadId: number) {
    let count = 0
    const totalTemp = data.reduce((acc: number, curr) => {
        if (curr.loadId === loadId) {
            count++
            acc += curr.sensor1

        }
        return acc
    }, 0)

    return totalTemp / count;
}

function findLatestSensor1ValueByLoadId(data: TemperatureSeries[], loadId: number): number | undefined {
    const filteredData = data.filter(item => item.loadId === loadId);
    filteredData.sort((a, b) => new Date(b.externalInsertionDate).getTime() - new Date(a.externalInsertionDate).getTime());
    return filteredData.length > 0 ? filteredData[0].sensor1 : 0;
}

function findLatestReadDateByLoadId(data: TemperatureSeries[], loadId: number): string | Date {
    const filteredData = data.filter(item => item.loadId === loadId);
    filteredData.sort((a, b) => new Date(b.externalInsertionDate).getTime() - new Date(a.externalInsertionDate).getTime());
    return filteredData.length > 0 ? dayjs(filteredData[0].externalInsertionDate).format('DD MMM YYYY hh:mm:ss A') : dayjs(new Date).format('DD MMM YYYY hh:mm:ss A');
}

function findMaxSensor1ValueByLoadId(data: TemperatureSeries[], loadId: number): number | undefined {
    const sensor1Values = data.filter(item => item.loadId === loadId).map(item => item.sensor1);

    if (sensor1Values.length === 0) return undefined;
    return Math.max(...sensor1Values);
}

function findMinSensor1ValueByLoadId(data: TemperatureSeries[], loadId: number): number | undefined {
    const sensor1Values = data.filter(item => item.loadId === loadId).map(item => item.sensor1);

    if (sensor1Values.length === 0) return undefined;
    return Math.min(...sensor1Values);
}


function aggregateByDate(data: TemperatureSeries[], shipment: any): PreparedChartData {

    return data.reduce((acc, curr) => {
        const load = shipment?.loads?.find((load: any) => load.id === curr.loadId)
        const loadName = load?.description
        if (!acc.data) {
            acc.data = []
            acc.seriesNames = []
            acc.upperThreshold = {}
            acc.lowerThreshold = {}
        }
        const formattedDate = dayjs(curr.externalInsertionDate).format('DD MMMM');
        const dateIndex = acc.data.findIndex(entry => entry[0] === formattedDate);
        if (!acc.upperThreshold[loadName]) {
            acc.upperThreshold[loadName] = load.temperatureSetting.upperThresholdWarning
        }
        if (!acc.lowerThreshold[loadName]) {
            acc.lowerThreshold[loadName] = load.temperatureSetting.lowerThresholdWarning
        }
        if (dateIndex === -1) {
            acc.data.push([formattedDate, curr.sensor1.toString()]);
        }
        else {
            acc.data[dateIndex].push(curr.sensor1.toString());
        }
        return acc;
    }, {} as PreparedChartData);
}


function solidChart(chartData: any) {

    const chart = anychart.line()

    const seriesColors: any[] = []

    // set chart padding
    chart.padding([10, 20, 5, 20]);

    // turn on chart animation
    chart.animation(true);

    // turn on the crosshair
    chart.crosshair(true);

    // set chart title text settings
    chart.title('Customers Activity during the Week');

    // set y axis title
    chart.yAxis().title('Temperature (°C)');

    // create logarithmic scale
    const logScale = anychart.scales.log();
    logScale.minimum(-50).maximum(50);

    // set scale for the chart, this scale will be used in all scale dependent entries such axes, grids, etc
    chart.yScale(logScale);

    // create data set on our data,also we can pud data directly to series
    const dataSet = anychart.data.set(chartData.data);


    // temp variable to store series instance
    let series;

    let idxMultiplier = 0

    chartData.seriesNames.map((seriesName: any, idx: number) => {
        series = chart.line(dataSet.mapAs({ x: 0, value: idx + 1 }));
        series.name(seriesName);
        series.markers().enabled(true).type('circle');
        series.legendItem().iconType('circle');

        seriesColors.push(series.color()); // Store series color

        chart.lineMarker(idxMultiplier + 1).value(chartData.upperThreshold[seriesName]).axis(chart.yAxis(0)).stroke({
            thickness: 2,
            color: seriesColors[idx],
            dash: '5 5',
        })
        chart.lineMarker(idxMultiplier + 2).value(chartData.lowerThreshold[seriesName]).axis(chart.yAxis(0)).stroke({
            thickness: 2,
            color: seriesColors[idx],
            dash: '5 5',
        })
        idxMultiplier = idxMultiplier + 1
    })



    chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]).align('left')

    return { chart, seriesColors }
}


const TemperatureChart = ({ data, title }: TemperatureChartProps) => {
    const chartContainerId = useRef(`chart-container-${uuidv4()}`)
    const chartRef = useRef<any>(null)
    const { id } = useParams()
    const shipmentId = Number(id)
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
    const tenantId = loggedInUser?.tenantId

    const {
        data: shipment,
        isFetching: shipmentSelectorFetching,
        isSuccess: shipmentLoaded,
    } = useGetShipmentByIdQuery({
        shipmentId,
        tenantId,
    })

    const [temperatureSeries, setTemperatureSeries] = useState<TemperatureSeries[]>([])
    const [chartData, setChartData] = useState<any>()

    useEffect(() => {

        if (window.anychart && chartData && shipment) {
            if (!chartRef.current) {
                const { chart, seriesColors } = solidChart(chartData);
                const loadColorData = shipment?.loads?.map((load: any, idx: number) => {
                    return {
                        loadName: load.description,
                        loadId: load.id,
                        color: seriesColors[idx]
                    }
                }
                )
                setLoadDropdownValues(loadColorData)
                chartRef.current = chart
                chartRef.current.container(chartContainerId.current)
            }

            chartRef.current.title(title)
            chartRef.current.draw()
        }
    }, [data, title, chartData, shipment])

    const [temperatureData, setTemperatureData] = useState<any>({
        color: '',
        loadName: 'Load 1 Temperature Data',
        date: '10 Oct 2023 09:00:25 AM',
        setPoint: '8',
        tempRange: '2 to 8',
        latestReading: '7.4',
        average: '7.6',
        upperThreshold: '11',
        upperCritical: '13',
        lowerThreshold: '5',
        lowerCritical: '3',
    })

    const [loadDropDownValues, setLoadDropdownValues] = useState<any[]>([])
    const [useGetTemperatureData, { data: tempData, error, isLoading, isSuccess }] = useGetTemperatureDataMutation()


    useEffect(() => {
        const fetchTemperatureData = async () => {
            const loadIds: number[] = (shipment?.loads?.map((load: any) => load.id) as number[])
            const temperatureSeriesResponse = await useGetTemperatureData({
                tenantId,
                body: {
                    loadIds,
                    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                    endDate: dayjs().format('YYYY-MM-DD'),
                }
            })
            if ('data' in temperatureSeriesResponse) {
                if (temperatureSeriesResponse.data.length > 0) {
                    setTemperatureSeries(temperatureSeriesResponse.data);
                }
            }
        }
        if (shipmentLoaded && shipment && temperatureSeries.length > 0) {
            const { loads } = shipment

            const result = aggregateByLoadId(temperatureSeries)

            const preparedChartData = aggregateByDate(temperatureSeries, shipment)

            loads?.map((load: any) => {
                preparedChartData.seriesNames.push(load.description)
            }
            )

            setChartData(preparedChartData)
        }
        else {
            fetchTemperatureData()
        }
    }, [shipmentLoaded, shipment, temperatureSeries])

    const [selectedLoad, setSelectedLoad] = useState<any>()
    const handleClear = () => {
        setSelectedLoad(null);
        setDropdownValue('');
    };
    const [dropdownValue, setDropdownValue] = useState('');
    const handleDropdownChange = (event: any) => {
        setDropdownValue(event.target.value);
        const { loadId, loadName } = loadDropDownValues.find((load: any) => load.color === event.target.value)
        const temperatureData = shipment?.loads?.find((load: any) => load.id === loadId)?.temperatureSetting
        if (temperatureData) {
            setTemperatureData({
                ...temperatureData,
                color: event.target.value,
                date: findLatestReadDateByLoadId(temperatureSeries, loadId),
                loadName: loadName,
                latestReading: findLatestSensor1ValueByLoadId(temperatureSeries, loadId),
                average: getAverageTemperature(temperatureSeries, loadId).toString(),
                tempRange: `${findMinSensor1ValueByLoadId(temperatureSeries, loadId)} to ${findMaxSensor1ValueByLoadId(temperatureSeries, loadId)}`,
                lowerCritical: temperatureData.lowerThresholdCritical.toString(),
                lowerThreshold: temperatureData.lowerThresholdWarning.toString(),
                upperCritical: temperatureData.upperThresholdCritical.toString(),
                upperThreshold: temperatureData.upperThresholdWarning.toString(),
            })
        }
        setSelectedLoad(loadId)
    };


    if (isLoading) {
        return (<Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh" // for the full viewport height, or use a specific value
            width="90vw" // for the full viewport width, or use a specific value
        >
            <SpinnerBoxBlock />
        </Box>
        )
    }
    if (isSuccess && (error || !tempData || tempData.length === 0)) {

        return (<Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
            width="90vw"
        >
            <Typography variant="h5" color="error">
                No Temperature Info Found
            </Typography>
        </Box>)
    }

    return (
        <TemperatureTabTile>
            <ConditionalRender condition={temperatureSeries.length > 0}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', pt: '20px', pb: '20px'
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', pt: '20px', pb: '20px'
                    }}>
                        <ColoredLoadDropdown
                            values={loadDropDownValues}
                            value={dropdownValue}
                            onChange={handleDropdownChange}
                        />
                        <IconButton onClick={handleClear} aria-label="clear">
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <ConditionalRender condition={selectedLoad}>
                        <LoadTemperatureCard data={temperatureData} />
                    </ConditionalRender>
                </Box>
                <Box id={chartContainerId.current}
                    style={{
                        width: '95%',
                        height: '500px',
                        margin: '20px',
                        padding: '20px',
                        borderRadius: '8px',
                        background: '#fff'
                    }}></Box>
                <Box>
                    <ConditionalRender condition={selectedLoad}>
                        <LoadTemperatureReadTable data={temperatureSeries.filter(x => x.loadId === selectedLoad)} load={shipment?.loads?.find((load: any) => load.id === selectedLoad)} />
                    </ConditionalRender>
                </Box>
            </ConditionalRender>
        </TemperatureTabTile>

    )
}

export default TemperatureChart
