import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../app/hooks/useNotificationStack'
import {
  useAcceptOrderByTenantIdMutation,
  useCreateDraftAssetHireMutation,
  useSubmitOrderMutation,
  useUpdateAssetHireByIdMutation
} from '../../../app/redux-fetch/apiShipment'
import TooltipButton from '../../../components/Buttons/TooltipButton'
import { PageHeader } from '../../../components/Headers/styles'
import AssetHireList from './AssetHireList'
import CustomerInformationForm from './CustomerInformationForm'
import messages from './messages'

const waypointSchema = z.object({
  id: z.number().optional(),
  bookingId: z.number().optional(),
  waypointTypeId: z.number().optional(),
  contactId: z.number().optional().nullable(),
  locationAuditId: z.number({ message: 'Location is required' }).min(1, { message: 'Location is required' }),
  scheduledDate: z.preprocess(
    (val) => {
      return (typeof val === 'string' ? new Date(val) : val)
    },
    z.date().min(new Date('1900-01-01'), { message: 'Too old' }),
  ),
  sequence: z.number().optional(),
  instructions: z.string().optional(),
});

const workforceDetailsSchema = z.object({
  id: z.number().optional(),
  bookingId: z.number().optional(),
  workforceTypeId: z.number({ message: 'Workforce Type is required' }).min(1, { message: 'Workforce Type is required' }),
  quantity: z.number({ message: 'Quantity is required' }).min(1, { message: 'Quantity is required' }),
});

const bookingSchema = z.object({
  id: z.number().optional(),
  orderId: z.number().optional(),
  quantity: z.number({ message: 'Quantity is required' }).min(1, { message: 'Quantity is required' }),
  assetConfigurationId: z.number({ message: 'Asset Configuration is required' }).min(1, { message: 'Asset Configuration is required' }),
  genSetRequired: z.boolean().optional(),
  displayOrder: z.number().default(1),
  waypoints: z.array(waypointSchema, { message: 'Stops are required' }).min(1, { message: 'At least one stop is required' }),
  workforce: z.array(workforceDetailsSchema).optional(),
});

const schema = z.object({
  id: z.number().optional(),
  customerId: z.number({ message: 'Customer is required' }).min(1, { message: 'Customer is required' }),
  tenantId: z.number().optional(),
  bookings: z.array(bookingSchema).optional(),
  submittedDate: z.date().optional(),
  customerReferenceNumber: z.string({ message: 'Customer Reference Number is required' }).min(1, { message: 'Customer Reference Number is required' }),
});


export type IAssetHireFormInput = z.infer<typeof schema>

interface AssetHireFormProps {
  initialValues?: Partial<IAssetHireFormInput>
  onSubmit: (data: IAssetHireFormInput) => void
  isEdit?: boolean
  onCancel?: () => void
}

const AssetHireForm: React.FC<AssetHireFormProps> = ({ initialValues, isEdit = false, onSubmit }) => {
  const { tenantId, customerId, isTenantUser } = useLoggedInUser()

  const { formatMessage } = useIntl()

  const methods = useForm<IAssetHireFormInput>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: initialValues || {
      customerId: customerId,
      bookings: [],
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = methods


  React.useEffect(() => {
    if (initialValues) {
      reset(initialValues)
    }
  }, [initialValues, reset])

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))


  const [createAssetHire, { isLoading }] = useCreateDraftAssetHireMutation()
  const [updateAssetHire, { isLoading: isUpdating }] = useUpdateAssetHireByIdMutation()

  const [submitAssetHire] = useSubmitOrderMutation()
  const [acceptAssetHire] = useAcceptOrderByTenantIdMutation()

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const navigate = useNavigate()

  const handleSubmitForm = async (
    data: IAssetHireFormInput,
    onAfterRequest?: (responseData?: any) => void
  ) => {
    const payload = data
    payload?.bookings?.forEach((booking, index) => {
      booking.displayOrder = index + 1
    })
    if (payload) {
      let response
      try {
        if (isEdit && payload.id) {
          response = await updateAssetHire({ id: payload.id, body: payload, tenantId, customerId }).unwrap()
          enqueueSuccess(messages.assetHireUpdated)
        } else {
          response = await createAssetHire({ body: payload, tenantId, customerId }).unwrap()
          enqueueSuccess(messages.assetHireCreated)
        }
      } catch (error) {
        console.error('Failed to submit asset hire:', error)
        enqueueFailure(messages.assetHireSubmissionFailed)
      } finally {
        if (onAfterRequest) {
          onAfterRequest(response)
        }
      }
    }
  }


  const handleSubmitAssetHire = async () => {
    const data = methods.getValues()
    const isValid = await methods.trigger()
    if (isValid) {
      handleSubmitForm(data, async (response) => {
        if (response) {
          try {
            await submitAssetHire({ tenantId, id: response.id }).unwrap()
            enqueueSuccess(messages.assetHireSubmitted)
            navigate('/assetHire')
          } catch (error) {
            enqueueFailure(messages.assetHireSubmissionFailed)
          }
        }
      })
    }
  }

  const handleAcceptAndSubmit = async () => {
    const data = methods.getValues()
    const isValid = await methods.trigger()
    if (isValid) {
      handleSubmitForm(data, async (response) => {
        if (response) {
          try {
            if (!data.submittedDate) {
              await submitAssetHire({ tenantId, id: response.id }).unwrap()
            }
            await acceptAssetHire({ tenantId, id: response.id }).unwrap()
            enqueueSuccess(messages.assetHireAccepted)
            navigate('/assetHire')
          } catch (error) {
            enqueueFailure(messages.assetHireAcceptFailed)
          }
        }
      })
    }
  }

  const header = () => {
    return formatMessage(messages.summary)
  }

  const handleSaveDraft = async () => {
    const data = methods.getValues()
    const isValid = await methods.trigger()
    if (isValid) {
      handleSubmitForm(data, () => {
        navigate('/assetHire')
      })
    } else {
      console.error('Validation failed:', errors)
    }
  }

  const submittedData = methods.watch('submittedDate')
  const bookings = methods.watch('bookings')

  const isBookingRequired = (!bookings || bookings?.length === 0)

  return (
    <Paper elevation={3} sx={{ padding: 4 }}>
      <Box
        sx={{
          backgroundColor: '#f0f0f0',
          padding: 2,
          textAlign: 'center',
          marginBottom: 4,
        }}
      >
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display='flex'
            flexDirection={isSmallScreen ? 'column' : 'row'}
            justifyContent='space-between'
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            sx={{ marginBottom: 2 }}
          >
            <PageHeader>{header()}</PageHeader>
            <Box>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleSaveDraft}
                sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
              >
                {isLoading || isUpdating ? (
                  <CircularProgress size={24} />
                ) : (
                  formatMessage(messages.saveDraft)
                )}
              </Button>
              <TooltipButton
                variant='contained'
                color='primary'
                onClick={handleSubmitAssetHire}
                disabled={isBookingRequired}
                showTooltip={isBookingRequired}
                tooltipProps={{
                  placement: 'top',
                  title: formatMessage(messages.noBooking),
                  children: (<></>)
                }}
                sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
              >
                {isLoading || isUpdating ? (
                  <CircularProgress size={24} />
                ) : (
                  formatMessage(messages.submitAssetHire)
                )}
              </TooltipButton>
              {isTenantUser && (<TooltipButton
                variant='contained'
                color='primary'
                onClick={handleAcceptAndSubmit}
                disabled={isBookingRequired}
                showTooltip={isBookingRequired}
                tooltipProps={{
                  placement: 'top',
                  title: formatMessage(messages.noBooking),
                  children: (<></>)
                }}
                sx={{ marginRight: 1, marginBottom: isSmallScreen ? 1 : 0 }}
              >
                {isLoading || isUpdating ? (
                  <CircularProgress size={24} />
                ) : (
                  submittedData ? formatMessage(messages.acceptAssetHire) : formatMessage(messages.submitAndAccept)
                )}
              </TooltipButton>)}
            </Box>
          </Box>
          <CustomerInformationForm />
          <AssetHireList />
        </form>
      </FormProvider>
    </Paper>
  )
}

export default AssetHireForm
