import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useGetAssetHireByIdQuery } from '../../../app/redux-fetch/apiShipment';
import SpinnerBoxBlock from '../../../components/Spinner/SpinnerBoxBlock';
import { OrderStatuses } from '../../../types/common';
import AssetHireForm, { IAssetHireFormInput } from './AssetHireForm';

const AssetHireFormContainer: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const [initialValues, setInitialValues] = useState<Partial<IAssetHireFormInput>>({})

  const { tenantId, customerId } = useLoggedInUser()

  const { data: assetHireData, isSuccess: shipmentIsLoaded } = useGetAssetHireByIdQuery({
    tenantId: tenantId,
    id: Number(id),
  }, { skip: !id })

  const isEdit = Boolean(id)
  const navigate = useNavigate()

  useEffect(() => {
    if (isEdit && id) {
      if (assetHireData) {
        const isSubmitted = assetHireData.status === OrderStatuses.Submitted || assetHireData.status === OrderStatuses.UnderReview
        const isAccepted = assetHireData.status === OrderStatuses.Accepted;
        if (isSubmitted || isAccepted) {
          navigate('/assetHire')
        }
        setInitialValues(assetHireData)
      }
    }
    else {
      setInitialValues({
        customerId,
        tenantId
      })
    }
  }, [isEdit, id, assetHireData])

  const handleSubmit = async (data: IAssetHireFormInput) => {
    // Stub for the hadnle submit
    console.log('')
  }

  if (isEdit && !shipmentIsLoaded) {
    return (<>
      <SpinnerBoxBlock />
    </>)
  }

  return <AssetHireForm initialValues={initialValues} onSubmit={handleSubmit} isEdit={isEdit} />
}

export default AssetHireFormContainer
