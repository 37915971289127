import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const LabelItem = styled(Typography) <{ padding?: string }>`
    color: #636363;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: left;
    padding: ${(props) => props.padding || '8px'};
`;

export const LabelWarningItem = styled(Typography) <{ padding?: string }>`
    color: #FF0000;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    padding: ${(props) => props.padding || '8px'};
    letter-spacing: 0.5px;
    `;