import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import { useGetAssetConfigurationsQuery } from '../../../app/redux-fetch/apiAssets';
import { useGetCustomersQuery, useGetUserTypesQuery } from '../../../app/redux-fetch/apiQuery';
import { useGetCurrenciesByCustomerIdQuery, useGetLocationAuditsQuery } from '../../../app/redux-fetch/apiShipment';
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer';
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../../components/Typographies/LabelItem';
import messages from './messages';
import { OrderDto } from './types';

interface AssetHireSummaryProps {
    data: OrderDto | null;
}

const AssetHireSummary: React.FC<AssetHireSummaryProps> = ({ data }) => {
    const isSmallScreen = false;
    const tenantId = data?.tenantId ?? 0;
    const customerId = data?.customerId ?? 0;

    const { formatMessage } = useIntl();

    const { data: customers = [] } = useGetCustomersQuery(tenantId)

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId, hideDisabled: true,
    })

    const { data: locations = [] } = useGetLocationAuditsQuery({
        tenantId,
        customerId: undefined,
    });


    const { data: workforcesTypes = [] } = useGetUserTypesQuery({
        tenantId,
    })
    const { data: assetConfigurations = [], isSuccess: assetConfigurationLoaded } = useGetAssetConfigurationsQuery({ tenantId })

    const getAssetConfigurationName = (assetConfigurationId?: number) => {
        return assetConfigurations.find((ac) => ac.id === assetConfigurationId)?.name;
    }

    const getLocationName = (locationId?: number) => {
        return locations.find((l: any) => l.id === locationId)?.name;
    }
    if (!data) return null;

    return (
        <Box>
            {data?.bookings?.map((booking, index) => (
                <>
                    <BoxWhiteContainer sx={{ mb: '32px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <LabelItem>{formatMessage(messages.assetHire)} {`# ${index + 1}`}</LabelItem>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.assetConfiguration)}
                                    value={getAssetConfigurationName(booking.assetConfigurationId) || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LabelItem>{formatMessage(messages.assetHirePickupDetails)}</LabelItem>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.location)}
                                    value={getLocationName(booking?.waypoints && booking?.waypoints[0].locationAuditId) || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.dateAndTime)}
                                    value={dayjs(booking?.waypoints && booking?.waypoints[0].scheduledDate).format('DD/MM/YYYY') ?? 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LabelItem>{formatMessage(messages.assetHireReturnDetails)}</LabelItem>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.location)}
                                    value={getLocationName(booking?.waypoints && booking?.waypoints[1].locationAuditId) || 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InfoDisplayItem
                                    label={formatMessage(messages.dateAndTime)}
                                    value={dayjs(booking?.waypoints && booking?.waypoints[1].scheduledDate).format('DD/MM/YYYY') ?? 'N/A'}
                                    isSmallScreen={isSmallScreen}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LabelItem>{formatMessage(messages.workforceDetails)}</LabelItem>
                            </Grid>
                            {booking.workforce && (
                                <>
                                    {booking.workforce.map((workforce, workforceIndex) => (
                                        <>
                                            {workforceIndex !== 0 && (
                                                <Grid item xs={0} md={3}></Grid>
                                            )}
                                            <Grid item xs={12} md={3}>
                                                <InfoDisplayItem
                                                    label={formatMessage(messages.workforceType)}
                                                    value={workforcesTypes.find((wt: any) => wt.id === workforce.workforceTypeId)?.name.toString() || 'N/A'}
                                                    isSmallScreen={isSmallScreen}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <InfoDisplayItem
                                                    label={formatMessage(messages.quantity)}
                                                    value={workforce.quantity?.toString() || 'N/A'}
                                                    isSmallScreen={isSmallScreen}
                                                />
                                            </Grid>
                                        </>
                                    ))}
                                </>
                            )}
                        </Grid>
                    </BoxWhiteContainer>
                </>
            ))}
        </Box>
    );
};

export default AssetHireSummary
