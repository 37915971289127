import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery';
import { convertToLabelValue } from '../../../app/utils';
import FormInput from '../../../components/Forms/FormInput';
import FormSelect from '../../../components/Forms/FormSelect';
import commonMessages from '../../../components/Messages/commonMessages';
import messages from './messages';

const CustomerInformationForm: React.FC = () => {
    const theme = useTheme();

    const { formatMessage } = useIntl();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { isCustomerUser } = useLoggedInUser();
    const { watch } = useFormContext();

    const tenantId = watch('tenantId');

    const { data: customers = [] } = useGetCustomersQuery(tenantId);

    return (
        <Box display="flex" flexWrap="wrap" gap={2} sx={{ marginBottom: 2 }}>
            <Box
                flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                minWidth={isSmallScreen ? '100%' : '200px'}
            >
                <FormSelect
                    name="customerId"
                    label={formatMessage(messages.customer)}
                    options={convertToLabelValue(customers)}
                    disabled={isCustomerUser}
                    fullWidth
                />
            </Box>
            <Box
                flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                minWidth={isSmallScreen ? '100%' : '200px'}
            >
                <FormInput
                    name="customerReferenceNumber"
                    label={formatMessage(messages.customerReferenceNumber)}
                    placeholder={formatMessage(commonMessages.pleaseEnter)}
                    fullWidth
                />
            </Box>
            <Box
                flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                minWidth={isSmallScreen ? '100%' : '200px'}
            >
                <FormInput
                    name="shipmentNumber"
                    label={formatMessage(messages.assetHireReferenceNumber)}
                    placeholder={formatMessage(messages.assetHireReferenceNumber)}
                    fullWidth
                    disabled={true}
                    helperText={formatMessage(messages.assetHireReferenceNumber)}
                />
            </Box>
        </Box>
    )
};

export default CustomerInformationForm;
