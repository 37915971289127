import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';

import {
  AccordionDetails,
  IconButton
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import { AccordionHeader } from '../../../components/Headers/styles';
import AssetHireInformationForm from './AssetHireInformationForm';
import AssetHirePickup from './AssetHirePickup';
import AssetHireReturn from './AssetHireReturn';
import messages from './messages';
import WorkforceDetails from './WorkforceDetails';

interface AssetHireProps {
  index: number
  removeAssetHire: (index: number) => void
  copyAssetHire: (index: number) => void
}

const AssetHire: React.FC<AssetHireProps> = ({
  index,
  removeAssetHire,
  copyAssetHire,
}) => {

  const [expanded, setExpanded] = useState(true);
  const { formatMessage } = useIntl();

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <StyledAccordion sx={theme => ({ backgroundColor: theme.palette?.accordion?.parent?.bg })}
      expanded={expanded}
      onChange={handleToggleAccordion}>
      <StyledAccordionSummary
        index={`asset-hire-content-${index}`} expanded={expanded}
      >
        <AccordionHeader sx={{ flexGrow: 1 }}>
          {formatMessage(messages.assetHire)} #{index + 1}
        </AccordionHeader>
        <IconButton onClick={() => copyAssetHire(index)} color="primary">
          <CopyIcon />
        </IconButton>
        <IconButton onClick={() => removeAssetHire(index)} color="error">
          <DeleteIcon />
        </IconButton>
      </StyledAccordionSummary>
      <AccordionDetails>
        <AssetHireInformationForm index={index} />
        <AssetHirePickup index={index} />
        <AssetHireReturn index={index} />
        <WorkforceDetails index={index} />
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default AssetHire
