/* eslint-disable react/prop-types */

import { AccountInfo, EventType, InteractionStatus, InteractionType } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'
import { Box, CssBaseline, Toolbar, Typography } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ThemeOptions, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useEffect, useMemo, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Route } from 'react-router'
import { Navigate, Routes } from 'react-router-dom'
import './App.css'
import { CLIENT_ID } from './app/axios/const'
import { AuthorizedAxios } from './app/axios/omnialog'
import { useAppDispatch, useAppSelector } from './app/hooks'
import logger from './app/middleware/log'
import { getCompanyName } from './app/utils'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'; // import UTC plugin
import useNavigation from './app/hooks/useNavigation'
import { AppBar } from './components/AppBar/AppBar'
import AppBarDetail from './components/AppBarDetail/AppBarDetail'
import { NavbarContext } from './components/AppBarDetail/NavbarContext'
import LocationBasedBreadcrumbs from './components/Breadcrumb/LocationBasedBreadcrumbs'
import { SubCategoryItem } from './components/Buttons/SubCategoryItem'
import { Drawer, DrawerHeader } from './components/Drawer/Drawer'
import Localization from './components/Localization/Localization'
import Logo from './components/Logo/Logo'
import NoMatch from './components/NoMatch/NoMatch'
import NotImplemented from './components/NoMatch/NotImplemented'
import TenantRoute from './components/Routes/TenantRoute'
import rootEnum from './components/Routes/rootEnum'
import MutationSpinner from './components/Spinner/MutationSpinner'
import SpinnerBlock from './components/Spinner/SpinnerBlock'
import { ThemeContext, ThemeContextState } from './components/Theme/Theme'
import { AssetTable } from './features/AssetsManagement/AssetTable'
import Office365Configuration from './features/CommonSettings/Office365/Office365'
import RoleTable from './features/CommonSettings/RoleManagement/RoleTable'
import CurrencyTable from './features/Currency/CurrencyTable'
import CustomerTable from './features/CustomerSettings/Customer/CustomerTable'
import Dashboard from './features/Dashboard/Dashboard'
import ErrorPage from './features/Error/Error'
import Journey from './features/Journey/Journey'
import Locations from './features/Locations/Locations'
import Note from './features/Notes/Note'
import AssetHireFormContainer from './features/Orders/AssetHire/AssetHireFormContainer'
import AssetHireTable from './features/Orders/AssetHire/AssetHireTable/AssetHireTable'
import ViewAssetHire from './features/Orders/AssetHire/ViewAssetHire'
import ShipmentFormContainer from './features/Orders/NewShipment/ShipmentFormContainer'
import ShipmentTable from './features/Orders/NewShipment/ShipmentTable/ShipmentTable'
import ViewShipment from './features/Orders/NewShipment/ViewShipment'
import ProductTabs from './features/Products/ProductsTab'
import ContactTabs from './features/Settings/Contacts/ContactTabs'
import CreateAsset from './features/TenantSettings/Asset/CreateAsset'
import AssetSettings from './features/TenantSettings/AssetSettings/AssetSettings'
import BrandingConfiguration from './features/TenantSettings/Components/BrandingConfiguration'
import ShippingTabs from './features/TenantSettings/Shipping/ShippingTabs'
import { fetchAllBrandingSelector } from './features/TenantSettings/selectors'
import { fetchBrandingThunk } from './features/TenantSettings/slice'
import { IBranding } from './features/TenantSettings/types'
import CreateTransport from './features/Transport/components/CreateTransport/CreateTransport'
import UnassignedRoutes from './features/Transport/components/Routes/UnassignedRoutes'
import TransportJobTable from './features/TransportJob/TransportJobTable'
import UserManagementTab from './features/UserManagement/Components/UserManagementTab/UserManagementTab'
import WorkforceTable from './features/Workforce/WorkforceTable'
import { fetchLoggedInUserSelector, isSignInSelector } from './features/selectors'
import {
  fetchLoggedInCustomerThunk,
  fetchLoggedInUserThunk,
  isSigninUserActions,
} from './features/slice'
import { ILoggedInUser } from './features/types'
import localizedMessages from './messages'
import { Anchor } from './types'

dayjs.extend(utc)
dayjs.extend(timezone)

function App(): ReactElement<any, any> {
  const { instance, inProgress } = useMsal()

  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const isSignIn = useSelector(isSignInSelector)
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const isLoggedInUserFetching = useAppSelector(fetchLoggedInUserSelector.isFetching)
  const isLoggedInUserLoaded = useAppSelector(fetchLoggedInUserSelector.isFinished)
  const { tenantId, customerId } = loggedInUser

  const { selectedCategory, selectedSubCategory, categories, appBarOpen } = useNavigation()

  useMsalAuthentication(InteractionType.Redirect, {
    extraQueryParameters: { domainUrl: window.location.origin },
    state: window.location.href,
  })

  const [themeContextState, setThemeContextState] = useState(new ThemeContextState())
  themeContextState.setThemeContextState = setThemeContextState

  const [readyState, setReadyState] = useState(false)
  const [authError, setAuthError] = useState()

  const brandingData = useAppSelector(fetchAllBrandingSelector.data)
  const brandingError = useAppSelector(fetchAllBrandingSelector.error)

  const branding = useMemo<IBranding | null>(() => {
    if (!brandingData) return null
    return brandingData
  }, [brandingData])

  useEffect(() => {
    if (!brandingError) return
    setReadyState(true)
  }, [brandingError])

  useEffect(() => {
    if (!branding) return

    const workingThemeSource = buildThemeSource(branding)
    themeContextState.createAndSetTheme(workingThemeSource)

    setReadyState(true)
  }, [branding])

  useEffect(() => {
    if (loggedInUser?.tenantId) dispatch(fetchBrandingThunk(loggedInUser?.tenantId))
  }, [loggedInUser])

  useEffect(() => {
    if (isSignIn.isSignIn) {
      const selectedCustomerTenant = localStorage.getItem('selectedCustomerTenant')
      if (selectedCustomerTenant) {
        if (selectedCustomerTenant.includes('customer-')) {
          const customerId = selectedCustomerTenant.split('-')[1]
          dispatch(fetchLoggedInUserThunk({ customerId: +customerId }))
        } else {
          const tenantId = selectedCustomerTenant.split('-')[1]
          dispatch(fetchLoggedInUserThunk({ tenantId: +tenantId }))
        }
      } else {
        dispatch(fetchLoggedInUserThunk({}))
      }
    }
  }, [isSignIn.isSignIn])

  useEffect(() => {
    if (!tenantId || !customerId) return
    dispatch(fetchLoggedInCustomerThunk({ tenantId, customerId }))
  }, [tenantId, customerId])

  useEffect(() => {
    instance.addEventCallback((event: any) => {
      logger.info('Event type', event.eventType)
      if (event.eventType === EventType.LOGIN_START || event.eventType === EventType.LOGOUT_START) {
        localStorage.removeItem('selectedCustomerTenant')
      }
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event?.payload
      ) {
        logger.info('Login success', event)
        instance.setActiveAccount(event.payload as AccountInfo)
        dispatch(isSigninUserActions.signIn())
        if (event?.payload.state) {
          window.location.href = event?.payload.state
        }
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        logger.error(event.error)
        logger.error('error ', event)
        logger.error('error ', event.error.errorMessage)
        if (event.error && event.error.errorMessage) {
          setAuthError(event.error.errorMessage)
        }
        if (
          event.error &&
          event.error.errorMessage &&
          event.error &&
          event.error.errorMessage.includes('Invalid user subdomain')
        ) {
          instance.loginRedirect({
            scopes: ['openid', 'offline_access', CLIENT_ID],
            extraQueryParameters: {
              domainUrl: window.location.origin,
              errorText: formatMessage(localizedMessages.invalidTenantError),
            },
          })
        } else {
          instance.loginRedirect({
            scopes: ['openid', 'offline_access', CLIENT_ID],
            extraQueryParameters: {
              domainUrl: window.location.origin,
              errorText: formatMessage(localizedMessages.generalLoginError),
            },
          })
        }
        if (event.eventType === EventType.LOGOUT_END && event?.payload) {
          dispatch(isSigninUserActions.signOut())
        }
      }
    })
  }, [setAuthError])

  void AuthorizedAxios()

  const [drawerPosition, setDrawerPosition] = useState('left')

  const setMenuPosition = (menuPosition: string) => {
    menuPosition === 'right' ? setDrawerPosition('right') : setDrawerPosition('left')
  }

  if (
    !readyState ||
    inProgress !== InteractionStatus.None ||
    isLoggedInUserFetching ||
    !isLoggedInUserLoaded
  ) {
    return <SpinnerBlock />
  } // eslint-disable-next-line
  const list = () => (
    <List>
      {categories.map((category) => (
        <ListItem
          key={category.name}
          disablePadding
          onClick={() => {
            category.action()
          }}
        >
          {category.element}
        </ListItem>
      ))}
    </List>
  )

  return (
    <NavbarContext.Provider value={{ setMenuPosition }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Localization>
          <ThemeContext.Provider value={themeContextState}>
            <ThemeContext.Consumer>
              {(value) => (
                <ThemeProvider theme={value.theme}>
                  <MutationSpinner />
                  <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar position='fixed' open={appBarOpen} anchor={drawerPosition as Anchor}>
                      <Toolbar>
                        <Box display='flex' flexGrow={1}>
                          <LocationBasedBreadcrumbs />
                        </Box>
                        <AppBarDetail />
                      </Toolbar>
                    </AppBar>
                    <Drawer variant='permanent' open={appBarOpen} anchor={drawerPosition as Anchor}>
                      <DrawerHeader sx={{ padding: 0 }}>
                        <Logo
                          companyName={getCompanyName(loggedInUser) ?? 'Omnialog'}
                          isRtl={drawerPosition === 'right'}
                        />
                      </DrawerHeader>
                      <Box display='flex' sx={{ overflow: 'hidden' }}>
                        <Box flex='0 0 auto'>{list()}</Box>
                        <Box flex='1' sx={{ overflow: 'hidden' }}>
                          <Box
                            padding={0}
                            bgcolor='white'
                            style={{ height: 'calc(100vh - 64px)' }}
                            sx={{ overflow: 'hidden' }}
                          >
                            <Typography
                              variant='h6'
                              sx={{
                                color: themeContextState.theme.palette.secondary.main,
                                fontWeight: 700,
                                ...(drawerPosition === 'left' && { marginLeft: '16px' }),
                                ...(drawerPosition === 'right' && { marginRight: '48px' }),
                                paddingTop: '16px',
                                height: '56px',
                              }}
                            >
                              {categories.find((c) => c.name === selectedCategory)?.name ?? ''}
                            </Typography>
                            <List disablePadding>
                              {categories
                                .find((c) => c.name === selectedCategory)
                                ?.items.map((item, index) => (
                                  <SubCategoryItem
                                    key={index}
                                    label={item.name}
                                    action={item.action}
                                    isSelected={selectedSubCategory === item.name}
                                  />
                                ))}
                            </List>
                          </Box>
                        </Box>
                      </Box>
                    </Drawer>
                    <Box
                      component='main'
                      sx={{ flexGrow: 1, p: 3, width: appBarOpen ? '70%' : '90%' }}
                    >
                      <AuthenticatedTemplate>
                        <DrawerHeader />
                        <Routes>
                          <Route
                            path={rootEnum.ROOT}
                            element={<Navigate replace to='/dashboard' />}
                          />
                          <Route path={rootEnum.DASHBOARD} element={<Dashboard />} />
                          <Route path={rootEnum.SHIPMENTS} element={<ShipmentTable />} />
                          <Route path={rootEnum.ASSET_HIRES} element={<AssetHireTable />} />
                          <Route path={rootEnum.CREATE_ASSET_HIRE} element={<AssetHireFormContainer />} />
                          <Route path={rootEnum.VIEW_ASSET_HIRE} element={<ViewAssetHire />} />
                          <Route path={rootEnum.EDIT_ASSET_HIRE} element={<AssetHireFormContainer />} />
                          <Route path={rootEnum.JOURNEY} element={<Journey />} />
                          <Route path={rootEnum.VIEW_SHIPMENT} element={<ViewShipment />} />
                          <Route path={rootEnum.EDIT_SHIPMENT} element={<ShipmentFormContainer />} />
                          <Route path={rootEnum.LOCATIONS} element={<Locations />} />
                          <Route path={rootEnum.CREATE_ASSET} element={<CreateAsset />} />
                          <Route
                            path={rootEnum.INTEGRATIONS}
                            element={<Office365Configuration />}
                          />
                          <Route path={rootEnum.USER_MANAGEMENT} element={<UserManagementTab />} />
                          <Route path={rootEnum.SHIPPING} element={<ShippingTabs />} />
                          <Route path={rootEnum.ROLES_PERMISSIONS} element={<RoleTable />} />
                          <Route path={rootEnum.ASSET_MANAGEMENT} element={<AssetSettings />} />
                          <Route path={rootEnum.BRANDING} element={<BrandingConfiguration />} />
                          <Route path={rootEnum.WORKFORCE} element={<WorkforceTable />} />
                          <Route path={rootEnum.UNASSIGNED_ROUTES} element={<UnassignedRoutes />} />
                          <Route path={rootEnum.ASSETS} element={<AssetTable />} />
                          <Route path={rootEnum.PRODUCTS} element={<ProductTabs />} />
                          <Route path={rootEnum.CURRENCY_SETTINGS} element={<CurrencyTable />} />
                          <Route path={rootEnum.CONTACTS_SETTINGS} element={<ContactTabs />} />
                          <Route path={'/notes'} element={<Note />} />
                          <Route
                            path={rootEnum.SHIPMENT_STEPPER}
                            element={<ShipmentFormContainer />}
                          />
                          <Route
                            path={rootEnum.CUSTOMERS}
                            element={
                              <TenantRoute>
                                <CustomerTable />
                              </TenantRoute>
                            }
                          />
                          <Route
                            path={rootEnum.CREATE_TRANSPORT}
                            element={
                              <TenantRoute>
                                <CreateTransport />
                              </TenantRoute>
                            }
                          />
                          <Route
                            path={rootEnum.TRANSPORT_JOB_LISTS}
                            element={
                              <TenantRoute>
                                <TransportJobTable />
                              </TenantRoute>
                            }
                          />
                          <Route path={rootEnum.COMING_SOON} element={<NotImplemented />} />
                          <Route path='*' element={<NoMatch />} />
                        </Routes>
                      </AuthenticatedTemplate>
                      <UnauthenticatedTemplate>
                        <ErrorPage msg={authError ?? ''}></ErrorPage>
                      </UnauthenticatedTemplate>
                    </Box>
                  </Box>
                </ThemeProvider>
              )}
            </ThemeContext.Consumer>
          </ThemeContext.Provider>
        </Localization>
      </LocalizationProvider>
    </NavbarContext.Provider>
  )
}

export default App

function buildThemeSource(branding: IBranding) {
  const { contrastTextColor, primaryPalletColor, secondaryPalletColor, textColor } = branding

  const themeSource: ThemeOptions = {
    palette: {
      primary: {
        contrastText: `#${contrastTextColor}`,
        main: `#${primaryPalletColor}`,
      },
      secondary: {
        main: `#${secondaryPalletColor}`,
      },
      text: {
        primary: `#${textColor}`,
      },
      accordion: {
        border: {
          radius: '8px',
        },
        parent: {
          bg: '#F6F6F6BF',
        },
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: '#636363',
            '&.active': {
              fontWeight: 'bold',
              color: '#636363',
            },
          },
        },
      },
    },
    transitions: {
      duration: {
        enteringScreen: 300,
        leavingScreen: 300,
      },
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
    },
  }
  return themeSource
}
