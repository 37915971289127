import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
import {
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetContactAuditByEntityIdQuery } from '../../../app/redux-fetch/apiCoreQuery'
import StyledAccordion from '../../../components/Accordions/StyledAccordion'
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary'
import FormDatePickerInput from '../../../components/Forms/FormDatePickerInput'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import commonMessages from '../../../components/Messages/commonMessages'
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem'
import { LabelItem } from '../../../components/Typographies/LabelItem'
import { useLocationModal } from '../hooks/useLocationModal'
import EmptyContainerReturn from './EmptyContainerReturn'
import FlowSection from './FlowSection'
import messages from './messages'
import { getAvailableLocations, getLocationAddress, mapContactsToOptions } from './utils'

interface DeliverToFormProps {
  index: number
}

const DeliverToForm: React.FC<DeliverToFormProps> = ({ index }) => {
  const { control, setValue, watch } = useFormContext()
  const { formatMessage } = useIntl()

  const [expanded, setExpanded] = useState(true)

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev)
  }

  const customerId = watch('customerId')
  const oldLocations = watch(`bookings.${index}.deliverTo.locationAudit`)

  const { openModal, ModalComponent, locationOptions, locationAudits, locations } = useLocationModal({
    onSave: (id) => {
      setValue(`bookings.${index}.deliverTo.location`, id)
    },
    customerId,
    oldShipmentLocations: oldLocations ? [oldLocations] : [],
  })

  const getLocationName = (locationId: number) => {
    return locationOptions.find((location) => location.value === locationId)?.label
  }

  const location = watch(`bookings.${index}.deliverTo.locationId`)
  const deliveryDate = dayjs(watch(`bookings.${index}.deliverTo.deliveryDate`)).format(
    'DD/MM/YYYY'
  )

  const getLocationIdFromLocationAudit = (locationAuditId: number) => {
    return locationAudits.find((loc: any) => loc.id === locationAuditId)?.locationId
  }

  const { data: contacts } = useGetContactAuditByEntityIdQuery(getLocationIdFromLocationAudit(location), {
    skip: !location,
    refetchOnMountOrArgChange: true,
  })


  const getContactOptions = useCallback(() => {
    return mapContactsToOptions(contacts)
  }, [contacts])

  const consignments = watch('bookings') || []

  const currentConsignment = consignments[index]

  const usedLocationIds: number[] = []

  if (currentConsignment?.shipFrom?.locationId) {
    usedLocationIds.push(currentConsignment.shipFrom.locationId)
  }

  if (currentConsignment?.deliverTo?.locationId) {
    usedLocationIds.push(currentConsignment.deliverTo.locationId)
  }
  const isEmptyContainerReturn = watch(`bookings.${index}.deliverTo.emptyContainerReturn`)

  const selectedLocation = locations.find((l: any) => l.id === locationAudits.find((loc: any) => loc.id === location)?.locationId)

  useEffect(() => {
    if (!isEmptyContainerReturn) return
    if (selectedLocation?.defaultTransportModeId) {
      setValue(`bookings.${index}.deliverTo.transitDetails.modeOfTransit`, selectedLocation?.defaultTransportModeId)
    } else {
      setValue(`bookings.${index}.deliverTo.transitDetails.modeOfTransit`, -1)
    }
  }, [selectedLocation, isEmptyContainerReturn]);

  return (
    <>
      {ModalComponent}
      <FlowSection hasBorder={!!isEmptyContainerReturn}>
        <StyledAccordion
          defaultExpanded
          sx={{ marginBottom: 2 }}
          expanded={expanded}
          onChange={handleToggleAccordion}
        >
          <StyledAccordionSummary
            index={`deliverto-content-${index}`}
            expanded={expanded}
            title={formatMessage(messages.deliverTo)}
          >
            <Grid container>
              <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <LabelItem>{formatMessage(messages.deliverTo)}</LabelItem>
              </Grid>
              {!expanded && (
                <>
                  <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem
                      label={formatMessage(messages.deliverTo)}
                      value={getLocationName(location) ?? 'N/A'}
                      isSmallScreen={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem
                      label={formatMessage(messages.deliveryDate)}
                      value={deliveryDate ?? 'N/A'}
                      isSmallScreen={false}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormSelect
                  name={`bookings.${index}.deliverTo.locationId`}
                  label={formatMessage(messages.location)}
                  options={getAvailableLocations(locationOptions, usedLocationIds)}
                  allOptions={locationOptions}
                  onChange={(e) => {
                    setValue(`bookings.${index}.deliverTo.contactId`, null)
                  }}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        openModal()
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      Add new
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormDatePickerInput
                  name={`bookings.${index}.deliverTo.deliveryDate`}
                  label={formatMessage(messages.scheduledDate)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name={`bookings.${index}.deliverTo.contactId`}
                  label={formatMessage(messages.contact)}
                  disableLabel={formatMessage(messages.noContactsAvailable)}
                  options={getContactOptions()}
                  actionLabel={
                    <Button
                      size='small'
                      onClick={() => {
                        setValue(`bookings.${index}.deliverTo.contactId`, null)
                      }}
                      sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                    >
                      {formatMessage(messages.clearSelection)}
                    </Button>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography variant='subtitle1' gutterBottom sx={{ color: '#636363' }}>
                    {formatMessage(messages.address)}
                  </Typography>
                  {location && (
                    <Button
                      onClick={() => openModal(selectedLocation)}
                      size='small'
                      variant='text'
                      sx={{
                        textTransform: 'none',
                        minWidth: 'auto',
                        ml: 1,
                        color: '#3D7FDA',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                        },
                      }}
                      endIcon={<EditLocationAltIcon fontSize='small' sx={{ color: 'inherit' }} />}
                    >
                      {formatMessage(commonMessages.editBtn)}
                    </Button>
                  )}
                </Box>
                <Typography variant='body2' sx={{ color: '#636363' }}>
                  {getLocationAddress(locationAudits, location)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name={`bookings.${index}.deliverTo.specialInstructions`}
                  label={formatMessage(messages.specialInstructions)}
                  placeholder={formatMessage(commonMessages.pleaseEnter)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Controller
                    name={`bookings.${index}.deliverTo.clearingRequired`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={field.value} />}
                        label={formatMessage(messages.clearingRequired)}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name={`bookings.${index}.deliverTo.emptyContainerReturn`}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked)
                            if (!e.target.checked) {
                              setValue(`bookings.${index}.emptyContainerReturn`, undefined)
                              setValue(`bookings.${index}.deliverTo.transitDetails`, undefined)
                            }
                            else {
                              setValue(`bookings.${index}.deliverTo.transitDetails`, {
                                carrierOption: 'noPreference',
                              })
                            }
                          }}
                        />
                      }
                      label={formatMessage(messages.useEmptyContainerReturn)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </FlowSection>
      <EmptyContainerReturn consignmentIndex={index} />
    </>
  )
}

export default DeliverToForm
