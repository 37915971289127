import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';
import { AccordionDetails, Box, Button, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetMeasureUnitsQuery } from '../../../app/redux-fetch/apiQuery';
import { useGetAllLoadTemplatesQuery, useGetAllLoadTypesQuery } from '../../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../../app/utils';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import BoxWhiteContainer from '../../../components/Containers/BoxWhiteContainer';
import CombinedSelect from '../../../components/Forms/CombinedSelect';
import FormInput from '../../../components/Forms/FormInput';
import FormSelect from '../../../components/Forms/FormSelect';
import { AccordionHeader } from '../../../components/Headers/styles';
import commonMessages from '../../../components/Messages/commonMessages';
import InfoDisplayItem from '../../../components/Typographies/InfoDisplayItem';
import { LabelItem, LabelWarningItem } from '../../../components/Typographies/LabelItem';
import { LoadType } from '../../../types/common';
import { useOrderFormContext } from '../context/OrderFormContext';
import PackageContentForm from './PackageContentForm';
import TemperatureControlledForm from './TemperatureControlledForm';
import messages from './messages';
import { LoadTemplateDto } from './types';


interface PackagingItemProps {
    consignmentIndex: number;
    packagingIndex: number;
    itemId: string;
    remove: (index: number) => void;
    copy: (index: number) => void;
}

const PackagingItem: React.FC<PackagingItemProps> = ({
    consignmentIndex,
    packagingIndex,
    itemId,
    remove,
    copy,
}) => {
    const { watch, control, setValue } = useFormContext();
    const { formatMessage } = useIntl();

    const packagingData =
        watch(`bookings.${consignmentIndex}.packaging.${packagingIndex}`) ?? {};


    const {
        packagingTypeId,
        weight,
        weightUnit,
        length,
        width,
        height,
        dimensionUnit,
        temperatureControlled,
        setPoint: temperatureSetPoint,
        packagingTemplateId,
        numberOfPackages,
        setPointUnitId,
    } = packagingData;



    const [expanded, setExpanded] = useState(true);

    const { currentPage } = useOrderFormContext();

    const { data: measureUnits = [] } = useGetMeasureUnitsQuery();

    const { data: packagingTypes } = useGetAllLoadTypesQuery();

    const { data: packagingTemplates = [] } = useGetAllLoadTemplatesQuery();

    const palletPackagingTemplatesOptions = useMemo(
        () =>
            convertToLabelValue(
                packagingTemplates
                    .filter((x: LoadTemplateDto) => x.loadTypeId === 2)
                    .map((x: LoadTemplateDto) => ({ name: x.name, id: x.id }))
            ),
        [packagingTemplates]
    );

    const resetFields = (fields: any) => {
        fields.forEach((field: any) => {
            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.${field}`,
                undefined
            );
        });
    };


    const containerTemplateOptions = useMemo(
        () =>
            convertToLabelValue(
                packagingTemplates
                    .filter((x: LoadTemplateDto) => x.loadTypeId === 1)
                    .map((x: LoadTemplateDto) => {
                        return { name: x.name, id: x.id };
                    })
            ),
        [packagingTemplates]
    );

    const massUnitOptions = useMemo(
        () =>
            convertToLabelValue(
                measureUnits
                    .filter((x) => x.measureTypeId === 1)
                    .map((x) => {
                        return { name: x.units, id: x.id };
                    })
            ),
        [measureUnits]
    );


    const getTemperatureUnit = useCallback(
        (id: number) => {
            return measureUnits.find((unit) => unit.id === id)?.units;
        },
        [measureUnits]
    );

    const lengthUnitOptions = useMemo(
        () =>
            convertToLabelValue(
                measureUnits
                    .filter((x) => x.measureTypeId === 4)
                    .map((x) => {
                        return { name: x.units, id: x.id };
                    })
            ),
        [measureUnits]
    );

    const getDimensionUnit = (unitId: number) => {
        return measureUnits.find((x) => x.id === unitId)?.units ?? '';
    };

    const getContainerType = (templateId: number) => {
        return packagingTemplates.find((x: any) => x.id === templateId)?.name;
    }

    const handleToggleAccordion = useCallback(() => {
        setExpanded((prev) => !prev);
    }, []);

    const handlePackagingTypeChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPackagingTypeId = Number(event.target.value);

        setValue(
            `bookings.${consignmentIndex}.packaging.${packagingIndex}.packagingTypeId`,
            newPackagingTypeId
        );

        const fieldsToReset = [
            'packagingTemplateId',
            'containerType',
            'weight',
            'weightUnit',
            'length',
            'width',
            'height',
            'dimensionUnit',
        ];

        resetFields(fieldsToReset);
        if (newPackagingTypeId === LoadType.parcel) {
            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.weightUnit`,
                massUnitOptions[0]?.value ?? 0
            );
            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`,
                lengthUnitOptions[0]?.value ?? 0
            );
        }
    };

    // Handler for packaging template change
    const handlePackagingTemplateChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPackagingTemplateId = Number(event.target.value);
        setValue(
            `bookings.${consignmentIndex}.packaging.${packagingIndex}.packagingTemplateId`,
            newPackagingTemplateId
        );

        const selectedTemplate = packagingTemplates.find(
            (template: LoadTemplateDto) => template.id === newPackagingTemplateId
        );

        if (selectedTemplate) {
            const {
                physicalProperties: {
                    length: templateLength,
                    width: templateWidth,
                    height: templateHeight,
                    lengthMeasureUnitId: templateDimensionUnit,
                },
            } = selectedTemplate;

            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.length`,
                templateLength !== undefined ? Number(templateLength) : undefined
            );
            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.width`,
                templateWidth !== undefined ? Number(templateWidth) : undefined
            );
            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.height`,
                templateHeight !== undefined ? Number(templateHeight) : undefined
            );
            setValue(
                `bookings.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`,
                templateDimensionUnit !== undefined ? Number(templateDimensionUnit) : undefined
            );

            resetFields(['weight', 'weightUnit']);
        } else {
            resetFields(['length', 'width', 'height', 'dimensionUnit']);
        }
    };

    const [isOpenPackageContent, setIsOpenPackageContent] = React.useState(false);

    const useDeclaredValue = watch(
        `bookings.${consignmentIndex}.packaging.${packagingIndex}.useDeclaredValue`,
        false
    );

    const products = watch(`bookings.${consignmentIndex}.packaging.${packagingIndex}.products`) ?? [];

    const handleTogglePackageContent = () => {
        setIsOpenPackageContent((prev) => !prev);
        if (!useDeclaredValue && products.length === 0) {
            setValue(`bookings.${consignmentIndex}.packaging.${packagingIndex}.products`, [
                {
                    productDescription: '',
                    countryOfOrigin: '',
                    quantity: 1,
                    unitPrice: 0,
                    totalValue: 0,
                    currency: '',
                    useProduct: false,
                }
            ]);
        }
    };

    const renderSummaryPage = () => {
        if (currentPage !== 3) return null;

        return (
            <Grid container>
                <BoxWhiteContainer sx={{ mb: '16px', pb: '0px', width: '100%' }}>
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                            >
                                <LabelItem>
                                    {formatMessage(messages.consignmentLoad)} #{packagingIndex + 1}
                                </LabelItem>
                                {!isOpenPackageContent && (
                                    <Button onClick={handleTogglePackageContent} sx={{ textTransform: 'none' }}>
                                        {formatMessage(messages.showContents)}
                                    </Button>
                                )}
                                {temperatureControlled && (<LabelWarningItem>{formatMessage(messages.temperatureControlled)}</LabelWarningItem>)}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: 'flex', justifyContent: 'flex-start' }}
                            >
                                <InfoDisplayItem
                                    label={formatMessage(messages.loadType)}
                                    value={
                                        packagingTypes?.find((x: any) => x.id === packagingTypeId)?.name ??
                                        formatMessage(commonMessages.na)
                                    }
                                    isSmallScreen={false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: 'flex', justifyContent: 'flex-start' }}
                            >
                                <InfoDisplayItem
                                    label={formatMessage(messages.noOfIdenticalLoads)}
                                    value={numberOfPackages ?? formatMessage(commonMessages.na)}
                                    isSmallScreen={false}
                                />
                            </Grid>
                            {packagingTypeId === LoadType.parcel && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.weight)}
                                            value={`${weight ?? 'N/A'} ${getDimensionUnit(weightUnit) ?? ''}`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.dimensions)}
                                            value={`${length ?? 'N/A'}x${width ?? 'N/A'}x${height ?? 'N/A'} ${getDimensionUnit(dimensionUnit) ?? ''
                                                }`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>
                                </>
                            )}

                            {packagingTypeId === LoadType.pallet && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.width)}
                                            value={`${width ?? formatMessage(commonMessages.na)
                                                } ${getDimensionUnit(dimensionUnit) ?? ''}`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.height)}
                                            value={`${height ?? 'N/A'
                                                } ${getDimensionUnit(dimensionUnit) ?? ''}`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>
                                </>
                            )}

                            {packagingTypeId === LoadType.container && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.containerType)}
                                            value={getContainerType(packagingTemplateId) ?? formatMessage(commonMessages.na)}
                                            isSmallScreen={false}
                                        />
                                    </Grid>
                                </>
                            )}

                            {temperatureControlled && (
                                <Grid
                                    item
                                    xs={12}
                                    md={2}
                                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                >
                                    <InfoDisplayItem
                                        label={formatMessage(messages.temperatureSetPoint)}
                                        value={`${temperatureSetPoint} ${getTemperatureUnit(setPointUnitId)}`}
                                        isSmallScreen={false}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} >
                        <PackageContentForm
                            consignmentIndex={consignmentIndex}
                            packagingIndex={packagingIndex}
                            isOpenPackageContent={isOpenPackageContent}
                            handleTogglePackageContent={handleTogglePackageContent}
                        />
                    </Grid>
                </BoxWhiteContainer>
            </Grid >
        );
    };

    const renderPackagingItemForm = () => {
        if (currentPage !== 2) return null;
        return (
            <StyledAccordion
                key={itemId}
                expanded={expanded}
                onChange={handleToggleAccordion}
            >
                <StyledAccordionSummary
                    index={`packaging-${packagingIndex + 1}`}
                    expanded={expanded}
                >
                    <AccordionHeader sx={{ flexGrow: 1 }}>
                        <Box display="flex" alignItems="center">
                            {formatMessage(messages.consignmentLoad)} #{packagingIndex + 1}
                            {!expanded && temperatureControlled && (<LabelWarningItem>{formatMessage(messages.temperatureControlled)}</LabelWarningItem>)}
                        </Box>
                    </AccordionHeader>
                    <IconButton onClick={() => copy(packagingIndex)} color="primary">
                        <CopyIcon />
                    </IconButton>
                    <IconButton onClick={() => remove(packagingIndex)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormSelect
                                name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.packagingTypeId`}
                                label={formatMessage(messages.loadType)}
                                options={convertToLabelValue(packagingTypes ?? [])}
                                placeholder={formatMessage(commonMessages.select)}
                                onChange={handlePackagingTypeChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormInput
                                name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.numberOfPackages`}
                                label={formatMessage(messages.noOfIdenticalLoads)}
                                type="number"
                                numberFormatProps={{
                                    allowOnlyIntegers: true,
                                    minValue: 1,
                                }}
                                fullWidth
                            />
                        </Grid>
                        {packagingTypeId === LoadType.container && ( // Container
                            <>
                                <Grid item xs={12} md={4}>
                                    <FormSelect
                                        name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.packagingTemplateId`}
                                        label={formatMessage(messages.containerType)}
                                        options={containerTemplateOptions}
                                        onChange={handlePackagingTemplateChange}
                                        placeholder="Please select"
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        {packagingTypeId === LoadType.pallet && ( // Pallet
                            <>
                                <Grid item xs={12} md={4}>
                                    <FormSelect
                                        name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.packagingTemplateId`}
                                        label={formatMessage(messages.palletType)}
                                        options={palletPackagingTemplatesOptions}
                                        onChange={handlePackagingTemplateChange}
                                        placeholder="Please select"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`bookings.${consignmentIndex}.packaging.${packagingIndex}.weight`}
                                        nameUnit={`bookings.${consignmentIndex}.packaging.${packagingIndex}.weightUnit`}
                                        labelValue={formatMessage(messages.weight)}
                                        numberFormatProps={{
                                            decimalScale: 2,
                                            fixedDecimalScale: true,
                                        }}
                                        labelUnitValue={formatMessage(messages.unitLabel)}
                                        optionsUnit={massUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`bookings.${consignmentIndex}.packaging.${packagingIndex}.height`}
                                        nameUnit={`bookings.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.height)}
                                        numberFormatProps={{
                                            decimalScale: 2,
                                            fixedDecimalScale: true,
                                        }}
                                        labelUnitValue={formatMessage(messages.unitLabel)}
                                        optionsUnit={lengthUnitOptions}
                                        showLabel={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} alignContent={'center'}>
                                    <Controller
                                        name={`bookings.${consignmentIndex}.packaging.${packagingIndex}.isStackable`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={<Switch {...field} checked={field.value} />}
                                                label={formatMessage(messages.isStackable)}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        {packagingTypeId === LoadType.parcel && ( // Parcel
                            <>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`bookings.${consignmentIndex}.packaging.${packagingIndex}.weight`}
                                        nameUnit={`bookings.${consignmentIndex}.packaging.${packagingIndex}.weightUnit`}
                                        labelValue={formatMessage(messages.weight)}
                                        labelUnitValue={formatMessage(messages.unitLabel)}
                                        numberFormatProps={{
                                            decimalScale: 2,
                                            fixedDecimalScale: true,
                                        }}
                                        optionsUnit={massUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`bookings.${consignmentIndex}.packaging.${packagingIndex}.length`}
                                        nameUnit={`bookings.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.length)}
                                        numberFormatProps={{
                                            decimalScale: 2,
                                            fixedDecimalScale: true,
                                        }}
                                        labelUnitValue={formatMessage(messages.unitLabel)}
                                        optionsUnit={lengthUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`bookings.${consignmentIndex}.packaging.${packagingIndex}.width`}
                                        nameUnit={`bookings.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.width)}
                                        labelUnitValue={formatMessage(messages.unitLabel)}
                                        optionsUnit={lengthUnitOptions}
                                        numberFormatProps={{
                                            decimalScale: 2,
                                            fixedDecimalScale: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`bookings.${consignmentIndex}.packaging.${packagingIndex}.height`}
                                        nameUnit={`bookings.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.height)}
                                        labelUnitValue={formatMessage(messages.unitLabel)}
                                        optionsUnit={lengthUnitOptions}
                                        numberFormatProps={{
                                            decimalScale: 2,
                                            fixedDecimalScale: true,
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                        <TemperatureControlledForm
                            consignmentIndex={consignmentIndex}
                            packagingIndex={packagingIndex}
                        />
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        );
    };

    return (
        <>
            {renderSummaryPage()}
            {renderPackagingItemForm()}
        </>
    );
};

export default PackagingItem;
