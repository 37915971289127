import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { mergeAll } from 'ramda'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import { useGetContactTypeQuery } from '../../app/redux-fetch/apiCoreQuery'
import FormDropdown from '../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import { getContactValidationSchema } from './contactValidation'
import { messages } from './messages'
import { IContact } from './types'

export interface IContactModalProps {
  isOpen: boolean
  contactToEdit: IContact
  handleSubmit?: (contact: IContact) => void
  handleCancel: () => void
}

const ContactFormModal = ({
  contactToEdit,
  isOpen,
  handleSubmit: handleClose,
  handleCancel,
}: IContactModalProps) => {
  const { formatMessage } = useIntl()

  const [contact, setContact] = useState(contactToEdit)
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: contactTypes = [] } = useGetContactTypeQuery({
    tenantId: tenantId ?? -1,
    customerId: contact.customerId,
    contactCategoryId: contact.contactCategoryId ?? 0
  }, { refetchOnMountOrArgChange: true })

  const [shouldValidate, setShouldValidate] = useState(false)
  const contactSchema = getContactValidationSchema(formatMessage)
  const isDisabled = !handleClose

  const validateContact = (contact: IContact, shouldValidate: boolean) => {
    try {
      shouldValidate && contactSchema.validateSync(contact, { abortEarly: false })
      setErrors({})
    } catch (err: any) {
      if (err.name === 'ValidationError') {
        const errs = mergeAll(err.inner.flatMap((e: any) => ({ [e.path]: e.errors })))
        setErrors(errs)
      }
    }
  }
  const handleSubmit = async () => {
    setShouldValidate(true)
    validateContact(contact, true)
    const isValid = await contactSchema.isValid(contact)
    if (isValid) {
      isValid && handleClose && handleClose(contact)
      setContact({})
      setErrors({})
    }
  }
  const handleCancelBtn = () => {
    setErrors({})
    handleCancel()
  }
  useEffect(() => {
    if (contactToEdit) {
      setContact(contactToEdit)
    } else {
      setContact({})
    }
  }, [contactToEdit])
  const [errors, setErrors] = useState<any>([])

  useEffect(() => {
    validateContact(contact, shouldValidate)
  }, [contact, shouldValidate])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, name: event.target.value })
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, email: event.target.value })
  }
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '20px',
            }}
          >
            {isDisabled ? 'CONTACT' : 'ADD/EDIT CONTACT'}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <Box sx={{ mb: '16px' }}>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.name)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <TextField
                fullWidth
                id={'name'}
                value={contact?.name ?? ''}
                onChange={handleNameChange}
                disabled={isDisabled}
                helperText={errors?.name}
                error={Boolean(errors?.name)}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.email)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <TextField
                fullWidth
                id={'email'}
                onChange={handleEmailChange}
                value={contact?.email ?? ''}
                helperText={errors?.email}
                disabled={isDisabled}
                error={Boolean(errors?.email)}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.phone)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <MuiTelInput
                fullWidth
                id={'phone'}
                onChange={(value, i) => {
                  setContact({ ...contact, phone: i.numberValue ?? '' })
                }}
                value={contact?.phone ?? ''}
                helperText={errors?.phone}
                disabled={isDisabled}
                error={Boolean(errors?.phone)}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                {formatMessage(messages.contactType)}
                <RequiredAsterisk />
              </SectionLabelTypography>
              <FormDropdown
                id={`${name}.contactTypeId`}
                items={contactTypes}
                disabled={isDisabled}
                onChange={(e, name, newValue) => {
                  setContact({ ...contact, contactTypeId: newValue })
                }}
                value={contactTypes.find((x) => x.id === contact?.contactTypeId ?? -1)?.id}
                error={Boolean(errors?.contactTypeId)}
                errorText={errors?.contactTypeId}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' variant='outlined' onClick={handleCancelBtn}>
            {formatMessage(messages.cancelBtn)}
          </Button>
          {!isDisabled && (
            <Button color='secondary' variant='contained' onClick={handleSubmit}>
              {contactToEdit.id ? formatMessage(messages.updateBtn) : formatMessage(messages.addBtn)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ContactFormModal
