export const OMNILOG_ORDERS_API_URL = process.env.REACT_APP_OMNILOG_ORDERS_API_URL || ''
export const OMNILOG_USERS_API_URL = process.env.REACT_APP_OMNILOG_USERS_API_URL
export const OMNILOG_ASSETS_API_URL = process.env.REACT_APP_OMNILOG_ASSETS_API_URL
export const OMNIALOG_CORE_API_URL = process.env.REACT_APP_OMNIALOG_CORES_API_URL || ''
export const REDIRECT_URL = process.env.REACT_APP_OMNILOG_REDIRECT_URL
export const AUTHORITY = process.env.REACT_APP_OMNILOG_AUTHORITY
export const AUTHORITY_DOMAIN = process.env.REACT_APP_OMNILOG_AUTHORITY_DOMAIN || ''
export const CLIENT_ID = process.env.REACT_APP_OMNILOG_CLIENT_ID || ''
export const TRANSPORT_FEATURE = process.env.REACT_APP_TRANSPORT_FEATURE === 'true'
export const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'error'
