import Button, { ButtonProps } from '@mui/material/Button';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';

interface TooltipButtonProps extends ButtonProps {
    onClick?: () => void;
    tooltipProps?: TooltipProps;
    showTooltip?: boolean;
    children: React.ReactNode;
}

const TooltipButton: React.FC<TooltipButtonProps> = ({
    onClick,
    color = 'primary',
    variant = 'contained',
    showTooltip = false,
    children,
    ...rest
}) => {
    return (
        <>
            {showTooltip ? (
                <Tooltip title={rest.tooltipProps?.title} {...rest.tooltipProps}>
                    <span>
                        <Button onClick={onClick} color={color} variant={variant} {...rest}>
                            {children}
                        </Button>
                    </span>
                </Tooltip>) : (
                <Button onClick={onClick} color={color} variant={variant} {...rest}>
                    {children}
                </Button >
            )}
        </>

    );
};

export default TooltipButton;