import { Box, Button, ButtonProps } from '@mui/material';
import React from 'react';

export interface ActionButtonProps extends ButtonProps {
    actionLabel?: React.ReactNode;
    children?: React.ReactNode;
}

const ActionButton: React.FC<ActionButtonProps> = ({
    actionLabel,
    children,
    ...buttonProps
}) => {
    return (
        <Box position="relative" mb={2}>
            <Button {...buttonProps}>
                {children}
            </Button>
            {actionLabel && (
                <Box
                    position="absolute"
                    top={-20}
                    right={-10}
                >
                    {actionLabel}
                </Box>
            )}
        </Box>
    );
};


export default ActionButton;
