import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser'
import { useGetAssetHireByIdQuery } from '../../../app/redux-fetch/apiShipment'
import { TableColumn, TableItem } from '../../../components/Headers/styles'
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography'

const ViewAssetHireHeader = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()

  const { tenantId, isTenantUser } = useLoggedInUser()

  const { data: assetHireData } = useGetAssetHireByIdQuery({
    tenantId,
    id: Number(id),
  }, { skip: !id })

  return (
    <Box display='flex' flexDirection='column' sx={{ pt: '32px' }}>
      <Box display='flex'>
        <TableTitleTypography>Asset Hire Information</TableTitleTypography>
      </Box>
      <Box display='flex'>
        <TableColumn>
          <Typography variant='body1'>Status</Typography>
        </TableColumn>
        {isTenantUser && (
          <TableColumn>
            <Typography variant='body1'>Customer</Typography>
          </TableColumn>
        )}
        <TableColumn>
          <Typography variant='body1'>Customer Reference Number</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant='body1'>Last Status Change</Typography>
        </TableColumn>
      </Box>
      <Box display='flex'>
        <TableItem>
          <Typography>{assetHireData.status}</Typography>
        </TableItem>
        {isTenantUser && (
          <TableItem>
            <Typography>{assetHireData?.customer?.name}</Typography>
          </TableItem>
        )}
        <TableItem>
          <Typography>{assetHireData?.customerReferenceNumber}</Typography>
        </TableItem>
        <TableItem>
          <Typography>{assetHireData && dayjs(assetHireData.lastStatusChangeDate).format('DD/MM/YYYY')}</Typography>
        </TableItem>
      </Box>
    </Box>
  )
}

export default ViewAssetHireHeader
