import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';

import {
  AccordionDetails,
  IconButton
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import StyledAccordion from '../../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../../components/Accordions/StyledAccordionSummary';
import { AccordionHeader } from '../../../components/Headers/styles';
import { useOrderFormContext } from '../context/OrderFormContext';
import PackagingForm from './PackagingForm';
import ShipFromDeliverToContainer from './ShipFromDeliverToContainer';
import messages from './messages';

interface ConsignmentFormProps {
  index: number
  removeConsignment: (index: number) => void
  copyConsignment: (index: number) => void
}

const ConsignmentForm: React.FC<ConsignmentFormProps> = ({
  index,
  removeConsignment,
  copyConsignment,
}) => {

  const [expanded, setExpanded] = useState(true);
  const { currentPage } = useOrderFormContext();
  const { formatMessage } = useIntl();

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev);
  };
  const renderShipFromDeliverToContainer = () => {
    return currentPage === 1 ? (
      <ShipFromDeliverToContainer consignmentIndex={index} />) : null
  }
  const renderPackagingForm = () => {
    return currentPage >= 2 ? (
      <PackagingForm consignmentIndex={index}
      />) : null
  }

  return (
    <StyledAccordion sx={theme => ({ backgroundColor: theme.palette?.accordion?.parent?.bg })}
      expanded={expanded}
      onChange={handleToggleAccordion}>
      <StyledAccordionSummary
        index={`consignment-content-${index}`} expanded={expanded}
      >
        <AccordionHeader sx={{ flexGrow: 1 }}>
          {formatMessage(messages.consignmentItem, { idx: index + 1 })}
        </AccordionHeader>
        <IconButton onClick={() => copyConsignment(index)} color="primary">
          <CopyIcon />
        </IconButton>
        <IconButton onClick={() => removeConsignment(index)} color="error">
          <DeleteIcon />
        </IconButton>
      </StyledAccordionSummary>
      <AccordionDetails>
        {renderShipFromDeliverToContainer()}
        {renderPackagingForm()}
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default ConsignmentForm
