import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import ToggleContainer from '../../../components/Containers/ToggleContainer'
import FlowSection from './FlowSection'
import TransitDetailsForm from './TransitDetailsForm'
import TransportIcon from './TransportIcon'
import messages from './messages'

interface TransitDetailsSectionProps {
    namePrefix: string
}

const TransitDetailsSection: React.FC<TransitDetailsSectionProps> = ({ namePrefix }) => {
    const { watch } = useFormContext()
    const { formatMessage } = useIntl()
    const [isOpen, setIsOpen] = useState(true)

    const transportMode = watch(`${namePrefix}.modeOfTransit`)

    const handleToggle = () => {
        setIsOpen((prev) => !prev)
    }

    return (
        <FlowSection icon={<TransportIcon id={transportMode} />} showIcon={isOpen}>
            <Box sx={{ p: `${!isOpen ? '8px' : '32px'}` }}>
                <ToggleContainer
                    title={formatMessage(messages.transitDetails)}
                    isOpen={isOpen}
                    handleToggle={handleToggle}
                >
                    <TransitDetailsForm namePrefix={namePrefix} />
                </ToggleContainer>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    {!isOpen && (
                        <Button onClick={handleToggle} sx={{ textTransform: 'none' }}>
                            {formatMessage(messages.showTransitDetails)}
                        </Button>
                    )}
                </Box>
            </Box>
        </FlowSection>
    )
}

export default TransitDetailsSection
