import { defineMessages } from 'react-intl'

const commonMessages = defineMessages({
  createBtn: {
    id: 'common.createBtn',
    defaultMessage: 'Create',
    description: 'The label for the create button',
  },
  editBtn: {
    id: 'common.editBtn',
    defaultMessage: 'Edit',
    description: 'The label for the edit button',
  },
  cancelBtn: {
    id: 'common.cancelBtn',
    defaultMessage: 'Cancel',
    description: 'The label for the cancel button',
  },
  updateBtn: {
    id: 'common.updateBtn',
    defaultMessage: 'Update',
    description: 'The label for the update button',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name',
    description: 'The label for the name',
  },
  previous: {
    id: 'common.previous',
    defaultMessage: 'Previous',
    description: 'The label for the previous button',
  },
  accept: {
    id: 'common.accept',
    defaultMessage: 'Accept',
    description: 'The label for the accept button',
  },
  next: {
    id: 'common.next',
    defaultMessage: 'Next',
    description: 'The label for the next button',
  },
  pleaseEnter: {
    id: 'common.pleaseEnter',
    defaultMessage: 'Please enter',
    description: 'The placeholder for the input field',
  },
  na: {
    id: 'common.na',
    defaultMessage: 'N/A',
    description: 'The label for not applicable',
  },
  select: {
    id: 'common.select',
    defaultMessage: 'Please Select',
    description: 'The label for the select',
  },
  addNew: {
    id: 'common.addNew',
    defaultMessage: 'Add New',
    description: 'The label for add new',
  },
  remove: {
    id: 'common.remove',
    defaultMessage: 'Remove',
    description: 'The label for remove',
  },
})
export default commonMessages
